import { Injectable } from "@angular/core";
import { Action, EntityInfo, HostConnectorService } from "./host-connector.service";
import { AddShoppingCartEntryRequestDto } from "@faro/order-angular-client";
import { Store } from "@ngrx/store";
import { addItemToActiveShoppingCart } from "src/app/order/shopping-cart-state/shopping-cart.actions";
import { Router } from "@angular/router";

@Injectable()
export class ActionProviderService {

    constructor(private readonly hostConnector: HostConnectorService,
        private readonly router: Router,
        private readonly store: Store
    ) { }

    dispatchShoppingCartAction(item: AddShoppingCartEntryRequestDto): void {
        if (this.hostConnector.isAppInWebViewContainer()) {
            this.hostConnector.emitAddShoppingCartEntryMessage(item)
        } else {
            this.store.dispatch(addItemToActiveShoppingCart({ params: item }));
        }
    }

    dispatchDocSetAction(entity: EntityInfo): void {
        if (this.hostConnector.isAppInWebViewContainer()) {
            this.hostConnector.emitDocSetMessage(entity)
        } else {
            console.log("Doing nothing... no docset action in non-embedded mode.");
        }
    }

    dispatchDetailAction(entity: EntityInfo, canViewCataloging: boolean, doubleClickShift: boolean): void {
        if (this.hostConnector.isAppInWebViewContainer() && doubleClickShift && canViewCataloging) {
            this.hostConnector.emitCatalogingEditorMessage(entity)
        } else {
            this.router.navigateByUrl(`details/${entity.programId}/${entity.itemId ? entity.itemId : entity.sequenceId}`)
        }
    }
}