<div class="in-out-container">
    <div class="in-out-controls">
        <div class="in-out-group">
            <button
                pRipple
                type="button"
                class="in-out-button"
                aria-label="IN Zeitstempel setzen"
                title="IN Zeitstempel setzen (i)"
                (click)="setValues('IN')"
                [disabled]="readonly"
            >
                IN
            </button>
            <div class="in-out-label" [ngClass]="{ disabled: readonly }" aria-live="polite">{{ inTimeCode$ | async }}</div>
        </div>
        <div class="in-out-group">
            <button
                pRipple
                type="button"
                class="in-out-button"
                aria-label="OUT Zeitstempel setzen"
                title="OUT Zeitstempel setzen (o)"
                (click)="setValues('OUT')"
                [disabled]="readonly"
            >
                OUT
            </button>
            <div class="in-out-label" [ngClass]="{ disabled: readonly }" aria-live="polite">{{ outTimeCode$ | async }}</div>
        </div>
    </div>
</div>
