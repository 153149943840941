import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';
import { HostConnectorService } from './services/host-connector.service';
import { ActionProviderService} from './services/action-provider.service';
import { NumberSeparatorPipe } from './pipes/number-separator.pipe';
import { UnsanitizedHtmlPipe } from './pipes/unsanitized-html.pipe';
import { MillisecondsToDurationPipe } from './pipes/milliseconds-to-duration.pipe';
import { SecureImageSrcPipe } from './pipes/secure-image-src.pipe';
import { BlobToObjectUrlPipe } from './pipes/blob-to-object-url.pipe';
import { HighlightRowDirective } from './directives/highlight-row.directive';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { ClickDoubleDirective } from './directives/click-dblclick.directive';
import { RestrictionDialogComponent } from './components/restriction-tooltip/restriction-tooltip.component';

@NgModule({
    declarations: [
        NumberSeparatorPipe,
        UnsanitizedHtmlPipe,
        MillisecondsToDurationPipe,
        DurationFormatPipe,
        SecureImageSrcPipe,
        BlobToObjectUrlPipe,
        HighlightRowDirective,
        ClickDoubleDirective,
        RestrictionDialogComponent
    ],
    exports: [
        NumberSeparatorPipe,
        UnsanitizedHtmlPipe,
        MillisecondsToDurationPipe,
        DurationFormatPipe,
        SecureImageSrcPipe,
        BlobToObjectUrlPipe,
        HighlightRowDirective,
        ClickDoubleDirective,
        RestrictionDialogComponent
    ],
    imports: [CommonModule],
    providers: [HostConnectorService, MessageService, ActionProviderService],
})
export class SharedModule {}
