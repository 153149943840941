import { Component, Input } from '@angular/core';
import { RightsRestrictions } from './restriction-tooltip.model';

@Component({
    selector: 'app-restriction-tooltip',
    templateUrl: './restriction-tooltip.component.html',
    styleUrls: ['./restriction-tooltip.component.scss'],
})
export class RestrictionDialogComponent {
    @Input()
    restrictions: RightsRestrictions | undefined;
}