import { SearchFieldSelectionEnum, SearchFieldSelectionOption } from '../search-state/search-options.state';
import { Store } from '@ngrx/store';
import { selectCountSelectedFilters, selectCountSelectedMatchFilters, selectListFacet, selectMatchFilters, selectQueryOfMatchFilter } from '../search-state/search-options.selectors';
import { ItemFacetKeys } from './facet.selectors';
import { first } from 'rxjs';

export function isDisplayFacetVisible(
    searchFieldSelection: SearchFieldSelectionOption,
    addSportsFacets: boolean
): string[] {
    let facets: string[] = [];
    if (searchFieldSelection.value === SearchFieldSelectionEnum.TOPIC) {
        facets.push('PROGRAM');
        facets.push('PEOPLE');
        facets.push('ORIGINATOR');
        if (addSportsFacets) {
            facets.push('SPORT');
            facets.push('EVENT');
            facets.push('YEAR');
        }
        facets.push('DESCRIPTOR');
    }
    if (searchFieldSelection.value === SearchFieldSelectionEnum.WORD) {
        facets.push('PROGRAM');
        facets.push('PEOPLE');
        facets.push('ORIGINATOR');
        facets.push('TEXTFIELDS');
        facets.push('PRIORITY');
        facets.push('DESCRIPTOR');
    }
    if (searchFieldSelection.value === SearchFieldSelectionEnum.IMAGE) {
        facets.push('IMAGE_PROGRAM');
        facets.push('IMAGE_PEOPLE');
        facets.push('IMAGE_ORIGINATOR');
        if (addSportsFacets) {
            facets.push('IMAGE_SPORT');
            facets.push('IMAGE_EVENT');
            facets.push('IMAGE_YEAR');
        }
        facets.push('IMAGE_PRIORITY');
        facets.push('IMAGE_CONTENT');
        facets.push('IMAGE_CAMERA_SETTINGS');
        facets.push('IMAGE_COUNTRY');
    }
    return [...new Set(facets)];
}

export function createFacetMap(store: Store): Map<string, any> {
    const searchModes = [
        {
            label: 'oder',
            value: 'OR',
        },
        {
            label: 'und',
            value: 'AND',
        },
    ];
    return new Map<string, any>([
        [
            'TEXTFIELDS',
            {
                filterType: 'MatchFilter',
                initialQuery: store.select(selectQueryOfMatchFilter(ItemFacetKeys.TEXTFIELDS)).pipe(first()),
                facetCountLabel: store.select(selectCountSelectedMatchFilters(ItemFacetKeys.TEXTFIELDS)),
                facetOptions: {
                    field: ItemFacetKeys.TEXTFIELDS,
                    collapsed: true,
                },
                header: 'Kontext',
            },
        ],
        [
            'DESCRIPTOR',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.DESCRIPTOR)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.DESCRIPTOR)),
                facetOptions: {
                    field: ItemFacetKeys.DESCRIPTOR,
                    isLoading: false,
                    collapsed: true,
                    searchModes,
                },
                header: 'Schlagwörter',
            },
        ],
        [
            'PEOPLE',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.PEOPLE)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.PEOPLE)),
                facetOptions: {
                    field: ItemFacetKeys.PEOPLE,
                    isLoading: false,
                    collapsed: true,
                    searchModes,
                },
                header: 'Personen',
            },
        ],
        [
            'SPORT',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.SPORT)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.SPORT)),
                facetOptions: {
                    field: ItemFacetKeys.SPORT,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sportart',
            },
        ],
        [
            'PROGRAM',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.PROGRAM)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.PROGRAM)),
                facetOptions: {
                    field: ItemFacetKeys.PROGRAM,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sendungen',
            },
        ],
        [
            'EVENT',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.EVENT)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.EVENT)),
                facetOptions: {
                    field: ItemFacetKeys.EVENT,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sport-Veranstaltung',
            },
        ],
        [
            'YEAR',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.YEAR)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.YEAR)),
                facetOptions: {
                    field: ItemFacetKeys.YEAR,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sport Jahr/Saison',
            },
        ],
        [
            'ORIGINATOR',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.ORIGINATOR)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.ORIGINATOR)),
                facetOptions: {
                    field: ItemFacetKeys.ORIGINATOR,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Urheberschaft',
            },
        ],
        [
            'PRIORITY',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.PRIORITY)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.PRIORITY)),
                facetOptions: {
                    field: ItemFacetKeys.PRIORITY,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Wertigkeit',
            },
        ],
        [
            'IMAGE_PEOPLE',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_PEOPLE)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_PEOPLE)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_PEOPLE,
                    isLoading: false,
                    collapsed: true,
                    searchModes,
                },
                header: 'Personen',
            },
        ],
        [
            'IMAGE_SPORT',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_SPORT)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_SPORT)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_SPORT,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sportart',
            },
        ],
        [
            'IMAGE_PROGRAM',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_PROGRAM)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_PROGRAM)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_PROGRAM,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sendungen',
            },
        ],
        [
            'IMAGE_COUNTRY',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_COUNTRY)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_COUNTRY)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_COUNTRY,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Land',
            },
        ],
        [
            'IMAGE_CAMERA_SETTINGS',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_CAMERA_SETTINGS)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_CAMERA_SETTINGS)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_CAMERA_SETTINGS,
                    isLoading: false,
                    collapsed: true,
                    searchModes,
                },
                header: 'Kameraeinstellungen',
            },
        ],
        [
            'IMAGE_CONTENT',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_CONTENT)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_CONTENT)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_CONTENT,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Inhalt',
            },
        ],
        [
            'IMAGE_EVENT',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_EVENT)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_EVENT)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_EVENT,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sport-Veranstaltung',
            },
        ],
        [
            'IMAGE_YEAR',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_YEAR)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_YEAR)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_YEAR,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Sport Jahr/Saison',
            },
        ],
        [
            'IMAGE_ORIGINATOR',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_ORIGINATOR)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_ORIGINATOR)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_ORIGINATOR,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Urheberschaft',
            },
        ],
        [
            'IMAGE_PRIORITY',
            {
                filterType: 'ValueFilter',
                facet: store.select(selectListFacet(ItemFacetKeys.IMAGE_PRIORITY)),
                facetCountLabel: store.select(selectCountSelectedFilters(ItemFacetKeys.IMAGE_PRIORITY)),
                facetOptions: {
                    field: ItemFacetKeys.IMAGE_PRIORITY,
                    isLoading: false,
                    collapsed: true,
                },
                header: 'Wertigkeit',
            },
        ],
    ]);
}
