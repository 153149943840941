<p-toast position="top-center" showTransformOptions="translateY(-100%)"></p-toast>

<div class="container">
    <header class="grid-header">
        <app-header *ngIf="!isAppInWebViewContainer"></app-header>
    </header>
    <main class="grid-content">
        <router-outlet></router-outlet>
    </main>
</div>
