<faro-facet-panel
    [header]="header"
    [collapsed]="collapsed"
    [headerIcon]="hideSearch ? undefined : 'pi pi-search'"
    [deleteIcon]="showDeleteIcon ? 'pi pi-trash' : ''"
    [headerIconHighLighted]="!searchFieldHidden || searchTerm.length > 0"
    [countLabel]="panelCountLabel"
    (headerIconClick)="onSearchIconClicked()"
    (deleteIconClick)="onDeleteIconClicked()"
    (collapsedChange)="onCollapseIconClicked($event)"
    [headerIconAriaLabel]="headerIconAriaLabel"
>
    <ng-container *ngIf="!searchFieldHidden">
        <div class="search-field-container">
            <div class="search-field">
                <div class="input-field">
                    <input
                        type="text"
                        [(ngModel)]="searchTerm"
                        [placeholder]="placeholderText"
                        id="{{ listFacetComponentId }}"
                    />
                    <button (click)="clearSearch()" *ngIf="searchTerm.length > 0" data-cy="facet-input-clear-button">
                        <i class="pi pi-times" aria-label="Facetten-Suchfeld löschen"></i>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="displayAndOrOperatorSwitch && availableSearchOperators.length > 0"
        ><div class="facet-select-container">
            <p-selectButton
                class="facet-select-button"
                [options]="availableSearchOperators"
                [(ngModel)]="selectedSearchOperator"
                (onChange)="selectionChanged($event)"
                [allowEmpty]="false"
                optionLabel="label"
                optionValue="value"
            ></p-selectButton>
        </div>
    </ng-container>

    <div *ngIf="facet">
        <div *ngIf="facet.staticItems?.length ?? 0 > 0" class="list">
            <faro-list-facet-section (checkBoxChanged)="itemStateChanged($event)" [items]="facet.staticItems">
            </faro-list-facet-section>
        </div>

        <ng-container *ngIf="isLoading">
            <div class="list">
                <div class="item">
                    <p-triStateCheckbox [disabled]="true"></p-triStateCheckbox>
                    <div class="loading">
                        <faro-loading-indicator-dots></faro-loading-indicator-dots>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="!isLoading && facet.dynamicItems.length > 0" class="list">
            <faro-list-facet-section (checkBoxChanged)="itemStateChanged($event)" [items]="facet.dynamicItems">
            </faro-list-facet-section>
        </div>

        <div *ngIf="showNoHitsHint">
            <div class="hint">Keine Treffer</div>
        </div>
    </div>
</faro-facet-panel>
