import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * This directive helps to fire only single or double click events. 
 * It relies on a debounce time to emit the last event in each burst.
 * When using (click) and (dblclick), the single click handler will fire twice and then the double click handler will fire once.
 * Solution from https://javascript.plainenglish.io/stop-the-horrible-clash-between-single-and-double-clicks-in-angular-5798ce90fd1a
 */
@Directive({
  selector: '[appClickSingle],[appClickDouble]',
})
export class ClickDoubleDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 300;
  @Output('appClickDouble') doubleClick = new EventEmitter();
  @Output('appClickSingle') singleClick = new EventEmitter();

  private clicksSubject = new Subject<MouseEvent>();
  private subscription: Subscription | undefined = undefined;

  ngOnInit() {
    this.subscription = this.clicksSubject.pipe(debounceTime(this.debounceTime)).subscribe(event => {
      if (event.type === 'click') {
        this.singleClick.emit(event);
      } else {
        this.doubleClick.emit(event);
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.clicksSubject.next(event);
  }
  @HostListener('dblclick', ['$event'])
  doubleClickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.clicksSubject.next(event);
  }
}