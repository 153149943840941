import { Duration, fromMilliseconds } from './duration';

/**
 * Gets the relative "length" of the sub range w.r.t. to the length of the total range.
 * I.e. if subRangeFrom === totalRangeFrom and subRangeTo === totalRangeTo, then length === 1.
 */
export function relativeLengthInTimeRange(
    subRangeFrom: Duration,
    subRangeTo: Duration,
    totalRangeFrom: Duration,
    totalRangeTo: Duration
): number {
    let relativeFrom = relativePositionInTimeRange(subRangeFrom, totalRangeFrom, totalRangeTo);
    let relativeTo = relativePositionInTimeRange(subRangeTo, totalRangeFrom, totalRangeTo);
    return relativeTo - relativeFrom;
}

/**
 * Gets the relative position in a given time range (from, to).
 */
export function relativePositionInTimeRange(position: Duration, rangeFrom: Duration, rangeTo: Duration): number {
    const range = timeRange(rangeFrom, rangeTo);
    return ((position.asMilliseconds() - rangeFrom.asMilliseconds()) / range?.asMilliseconds()) * 100;
}

/**
 * Get the duration between from and to.
 * E.g. if from === 00:00:10 and to === 00:00:25, then timeRange(from, to) === 00:00:15
 */
export function timeRange(from: Duration, to: Duration): Duration {
    return fromMilliseconds(to.asMilliseconds() - from.asMilliseconds());
}

/**
 * Verifies that a (non-empty) subrange is contained in a given range
 */
export function isSubRange(subRangeFrom: Duration, subRangeTo: Duration, rangeFrom: Duration, rangeTo: Duration): boolean {
    return subRangeFrom.asMilliseconds() >= rangeFrom.asMilliseconds()
        && subRangeFrom.asMilliseconds() < subRangeTo.asMilliseconds()
        && subRangeTo.asMilliseconds() <= rangeTo.asMilliseconds();
}
