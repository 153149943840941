<div class="dialog-content" role="dialog" aria-labelledby="dialogTitle">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">{{ config.data.title }}</h2>
        <div>
            <button pRipple class="close-icon" autofocus aria-label="Dialog schliessen" (click)="closeDialog()">
                <img src="assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <div>
        <h3 class="music-header">In diesem Beitrag verwendete Musik</h3>
        <div *ngIf="!loading">
            <p-table
                tableStyleClass="music-table"
                [scrollable]="true"
                responsiveLayout="scroll"
                [value]="itemMusicRows ?? []"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let h of tableHeader" class="{{ h.scssClass }}" [attr.aria-label]="h.value"><span class="h-offscreen">Spaltentitel</span>{{ h.value }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-itemMusicRows>
                    <tr class="table-row music-table-row">
                        <td>{{ itemMusicRows.composer }}</td>
                        <td>{{ itemMusicRows.artist }}</td>
                        <td>{{ itemMusicRows.title }}</td>
                        <td>{{ itemMusicRows.duration }}</td>
                        <td>{{ itemMusicRows.label }}</td>
                        <td>{{ itemMusicRows.remarks }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <ng-container *ngIf="itemMusicRows && itemMusicRows.length === 0">
                <div class="no-music">Keine verwendete Musik</div>
            </ng-container>
        </div>
        <ng-container *ngIf="loading">
            <div class="loading-music">
                <p-progressSpinner
                    strokeWidth="2"
                    animationDuration="5s"
                    styleClass="custom-spinner"
                ></p-progressSpinner>
            </div>
        </ng-container>
    </div>
    <div>
        <h3 class="music-header">In dieser Sendung verwendete Musik</h3>
        <div *ngIf="!loading">
            <p-table
                tableStyleClass="music-table"
                [scrollable]="true"
                responsiveLayout="scroll"
                [value]="programMusicRows ?? []"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let h of tableHeader" class="{{ h.scssClass }}" [attr.aria-label]="h.value"><span class="h-offscreen">Spaltentitel</span>{{ h.value }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-programMusicRows>
                    <tr class="table-row music-table-row">
                        <td>{{ programMusicRows.composer }}</td>
                        <td>{{ programMusicRows.artist }}</td>
                        <td>{{ programMusicRows.title }}</td>
                        <td>{{ programMusicRows.duration }}</td>
                        <td>{{ programMusicRows.label }}</td>
                        <td>{{ programMusicRows.remarks }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <ng-container *ngIf="programMusicRows && programMusicRows.length === 0">
                <div class="no-music">Keine verwendete Musik</div>
            </ng-container>
        </div>
        <ng-container *ngIf="loading">
            <div class="loading-music">
                <p-progressSpinner
                    strokeWidth="2"
                    animationDuration="5s"
                    styleClass="custom-spinner"
                ></p-progressSpinner>
            </div>
        </ng-container>
    </div>
</div>
