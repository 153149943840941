import { createReducer, on } from '@ngrx/store';
import {
    getAvailableStorageCategories,
    resetAllFilters,
    resetFiltersForFacet,
    resetSearch,
    setAllExcludedStorageCategories,
    setAvailableStorageCategories,
    setFilterOperator,
    setHitlistSortingAttribute,
    setHitlistSortingOrder,
    setHitlistSortingOrderAndAttribute,
    setProfileFilters,
    setSearchFieldSelection,
    setSearchInfoDialogPreferences,
    triggerResetFacetSearch,
    updateDateFacetRange,
    updateFilter,
    updateMatchFilter,
    updateSearchInput,
} from './search-options.actions';
import {
    HitlistSortingAttribute,
    HitlistSortingOrder,
    SearchFieldSelectionEnum,
    SearchFieldSelectionOption,
    SearchOptions,
    ValueFilterOperator,
} from './search-options.state';
import { FilterType, MatchFilter, ValueFilter } from '@faro/searchapi-angular-client';
import { dispatchSearch } from './search-result.actions';
import { setSearchProfileSettings } from './search-profile.actions';
import { ItemFacetKeys } from '../filters/facet.selectors';
import { isDisplayFacetVisible } from '../filters/display-facets.model';

export const initialState: SearchOptions = {
    query: '',
    searchType: { value: SearchFieldSelectionEnum.TOPIC },
    itemSearchOptions: {
        includingRecyclings: true,
        highDefinitionOnly: false,
        includingDopeSheets: false,
    },
    excludedMediaTypes: {
        excludeAudio: false,
        excludeVideo: false,
    },
    availableStorageCategories: [],
    allExcludedStorageCategories: [],
    allExcludedStorageCategoriesOutdated: true,
    filters: [],
    filterOperators: [],
    matchFilters: [],
    profileFilters: [],
    includeSportsFilters: false,
    isSearchUpdateAvailable: false,
    resetAllFacetSearchResetTriggered: false,
    sorting: {
        attribute: HitlistSortingAttribute.RELEVANCE,
        order: HitlistSortingOrder.DESCENDING,
    },
    dateFilter: {
        from: null,
        to: null,
    },
    loading: {
        storageCategories: false,
    },
    displayActivateSearchInfoDialog: localStorage.getItem('showSearchInfoDialog') !== 'false',
};

export const searchOptionsReducer = createReducer(
    initialState,
    on(setSearchProfileSettings, (state: SearchOptions, { searchProfile }): SearchOptions => {
        return {
            ...state,
            itemSearchOptions: { ...state.itemSearchOptions, includingDopeSheets: searchProfile.searchInDocumentTexts },
        };
    }),
    on(setSearchProfileSettings, (state: SearchOptions, { searchProfile }): SearchOptions => {
        return {
            ...state,
            excludedMediaTypes: {
                excludeAudio: searchProfile.excludedMediaTypes.includes('audio'),
                excludeVideo: searchProfile.excludedMediaTypes.includes('video'),
            },
        };
    }),
    on(updateSearchInput, (state: SearchOptions, { query }): SearchOptions => {
        return { ...state, query, isSearchUpdateAvailable: true };
    }),
    on(updateFilter, (state: SearchOptions, { filter }): SearchOptions => {
        const filteredFilters = state.filters.filter(f => !(f.field === filter.field && f.value === filter.value));
        switch (filter.filterType) {
            case FilterType.None:
                return { ...state, filters: filteredFilters, isSearchUpdateAvailable: true };
            case FilterType.Inclusion:
            case FilterType.Exclusion:
                filteredFilters.push(filter);
                return { ...state, filters: filteredFilters, isSearchUpdateAvailable: true };
            default:
                return state;
        }
    }),
    on(updateMatchFilter, (state: SearchOptions, { filter }): SearchOptions => {
        const filteredFilters = state.matchFilters.filter(f => f.field !== filter.field);
        switch (filter.filterType) {
            case FilterType.None:
                return { ...state, matchFilters: filteredFilters, isSearchUpdateAvailable: true };
            case FilterType.Inclusion:
            case FilterType.Exclusion:
                if ((filter.query ?? '').length > 0) {
                    filteredFilters.push(filter);
                }
                return { ...state, matchFilters: filteredFilters, isSearchUpdateAvailable: true };
            default:
                return state;
        }
    }),
    on(triggerResetFacetSearch, (state: SearchOptions): SearchOptions => {
        return { ...state, resetAllFacetSearchResetTriggered: true };
    }),
    on(resetAllFilters, (state: SearchOptions): SearchOptions => {
        return {
            ...state,
            filters: [],
            matchFilters: [],
            dateFilter: { from: null, to: null },
            isSearchUpdateAvailable: true,
            resetAllFacetSearchResetTriggered: false,
        };
    }),
    on(setProfileFilters, (state: SearchOptions, { filters }): SearchOptions => {
        const isImageSearchType = state.searchType.value === SearchFieldSelectionEnum.IMAGE;
        const newProfileFilters: ValueFilter[] = [];
        filters.forEach(f => {
            newProfileFilters.push({
                field: isImageSearchType
                    ? 'Sequence.Item.Program.StorageCategory.CategoryLevels.AllIds'
                    : 'Item.Program.StorageCategory.CategoryLevels.AllIds',
                filterType: 'Exclusion',
                value: f,
            });
        });
        return { ...state, profileFilters: newProfileFilters };
    }),
    on(setHitlistSortingOrder, (state: SearchOptions, { sortingOrder }): SearchOptions => {
        return { ...state, sorting: { ...state.sorting, order: sortingOrder } };
    }),
    on(setHitlistSortingAttribute, (state: SearchOptions, { sortingAttribute }): SearchOptions => {
        return { ...state, sorting: { ...state.sorting, attribute: sortingAttribute } };
    }),
    on(
        setHitlistSortingOrderAndAttribute,
        (state: SearchOptions, { sortingOrder, sortingAttribute }): SearchOptions => {
            return { ...state, sorting: { ...state.sorting, order: sortingOrder, attribute: sortingAttribute } };
        }
    ),
    on(updateDateFacetRange, (state: SearchOptions, dateFilter): SearchOptions => {
        const sameFromDateIsoString = dateFilter.from?.toISOString() === state.dateFilter.from?.toISOString();
        const sameToDateIsoString = dateFilter.to?.toISOString() === state.dateFilter.to?.toISOString();
        const sameDateRange = sameFromDateIsoString && sameToDateIsoString;
        return { ...state, dateFilter, isSearchUpdateAvailable: !sameDateRange };
    }),
    on(resetFiltersForFacet, (state: SearchOptions, { field }): SearchOptions => {
        const filters = state.filters.filter(f => f.field !== field);
        return { ...state, filters, isSearchUpdateAvailable: true };
    }),
    on(dispatchSearch, (state: SearchOptions): SearchOptions => {
        return { ...state, isSearchUpdateAvailable: false };
    }),
    on(setSearchProfileSettings, (state: SearchOptions): SearchOptions => {
        return {
            ...state,
            allExcludedStorageCategoriesOutdated: true,
        };
    }),
    on(setAllExcludedStorageCategories, (state: SearchOptions, { excluded }): SearchOptions => {
        const excludedUppercase = excluded.map(e => e.toUpperCase());
        return {
            ...state,
            allExcludedStorageCategories: excludedUppercase,
            allExcludedStorageCategoriesOutdated: false,
        };
    }),
    on(setSearchFieldSelection, (state: SearchOptions, { searchFieldSelection }): SearchOptions => {
        const [validSelectedValueFilters, validSelectedMatchFilters] = getValidFilters(
            searchFieldSelection,
            state.filters,
            state.matchFilters,
            state.includeSportsFilters
        );
        return {
            ...state,
            searchType: searchFieldSelection,
            filters: validSelectedValueFilters,
            matchFilters: validSelectedMatchFilters,
            isSearchUpdateAvailable: true,
        };
    }),
    on(getAvailableStorageCategories, (state: SearchOptions): SearchOptions => {
        return {
            ...state,
            loading: {
                storageCategories: true,
            },
        };
    }),
    on(setAvailableStorageCategories, (state: SearchOptions, { storageCategories }): SearchOptions => {
        return {
            ...state,
            availableStorageCategories: storageCategories,
            loading: {
                storageCategories: false,
            },
        };
    }),
    on(setSearchProfileSettings, (state: SearchOptions, { searchProfile }): SearchOptions => {
        return {
            ...state,
            includeSportsFilters: searchProfile.includeSportsFilters,
        };
    }),
    on(setFilterOperator, (state: SearchOptions, { field, operator }): SearchOptions => {
        const filterOperator: ValueFilterOperator = {
            field,
            operator,
        };
        const filters = state.filterOperators.filter(v => v.field != field);
        const updatedFilterOperators = [...filters, filterOperator];
        return {
            ...state,
            filterOperators: updatedFilterOperators,
        };
    }),
    on(resetSearch, (state: SearchOptions): SearchOptions => {
        return {
            ...state,
            query: '',
            searchType: { value: SearchFieldSelectionEnum.TOPIC },
        };
    }),
    on(setSearchInfoDialogPreferences, (state: SearchOptions): SearchOptions => {
        return {
            ...state,
            displayActivateSearchInfoDialog: false, // Ignores the localStorage state to show the dialog only once per session
        };
    })
);

function getValidFilters(
    searchFieldSelection: SearchFieldSelectionOption,
    filters: ValueFilter[],
    matchFilters: MatchFilter[],
    displaySportsFacets: boolean
): [ValueFilter[], MatchFilter[]] {
    // available filter keys (string)
    const availableFacets = isDisplayFacetVisible(searchFieldSelection, displaySportsFacets);
    // available filter field ids (enum values)
    const availableFacetFields = availableFacets.map(v => {
        const idx = Object.keys(ItemFacetKeys).indexOf(v);
        return Object.values(ItemFacetKeys)[idx];
    });
    if (availableFacets[0].startsWith('IMAGE_')) {
        filters = filters.map(f => {
            return {
                ...f,
                field: f.field?.startsWith('Item') ? getCorrespondingFieldForImage(f) : f.field,
            };
        });
        matchFilters = matchFilters.map(f => {
            return {
                ...f,
                field: f.field?.startsWith('Item') ? getCorrespondingFieldForImage(f) : f.field,
            };
        });
    } else if (!availableFacets[0].startsWith('IMAGE_')) {
        filters = filters.map(f => {
            return {
                ...f,
                field: f.field?.startsWith('Sequence') ? getCorrespondingFieldForStandard(f) : f.field,
            };
        });
        matchFilters = matchFilters.map(f => {
            return {
                ...f,
                field: f.field?.startsWith('Sequence') ? getCorrespondingFieldForStandard(f) : f.field,
            };
        });
    }

    const validSelectedValueFilters: ValueFilter[] = [];
    const validSelectedMatchFilters: MatchFilter[] = [];
    availableFacetFields.map((availableFacetField: ItemFacetKeys) => {
        filters.map((valueFilter: ValueFilter) => {
            if (valueFilter.field === availableFacetField) {
                validSelectedValueFilters.push(valueFilter);
            }
        });
        matchFilters.map((matchFilter: MatchFilter) => {
            if (matchFilter.field === availableFacetField) {
                validSelectedMatchFilters.push(matchFilter);
            }
        });
    });
    return [validSelectedValueFilters, validSelectedMatchFilters];
}

function getCorrespondingFieldForImage(filter: ValueFilter) {
    return ItemFacetKeys[
        `IMAGE_${
            Object.keys(ItemFacetKeys)[Object.values(ItemFacetKeys).indexOf(filter.field as unknown as ItemFacetKeys)]
        }` as keyof typeof ItemFacetKeys
    ];
}

function getCorrespondingFieldForStandard(filter: ValueFilter) {
    return ItemFacetKeys[
        `${
            Object.keys(ItemFacetKeys)[Object.values(ItemFacetKeys).indexOf(filter.field as unknown as ItemFacetKeys)]
        }`.split('IMAGE_')[1] as keyof typeof ItemFacetKeys
    ];
}
