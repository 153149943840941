import { createReducer, on } from '@ngrx/store';
import { resetSearch, updateFacetSearchInput } from './search-options.actions';
import {
    cancelSearch,
    dispatchEmptySearch,
    dispatchEmptySearchAfterTypeChange,
    dispatchSearch,
    dispatchSearchNextPage,
    notifyOfSearchError,
    setFacetSearchResponse,
    setFacetsOnly,
    setScrollTopPosition,
    setSearchResponse,
} from './search-result.actions';
import { SearchResult } from './search-result.state';

export const initialState: SearchResult = {
    lastResponse: undefined,
    isBeforeFirstSearch: true,
    displaySavedSearch: false,
    isLoading: false,
    isLoadingNextPage: false,
    isLoadingFacet: new Map(),
    isCanceled: false,
    error: '',
    hasError: false,
    scrollTopPosition: 0,
};

export const searchResultReducer = createReducer(
    initialState,
    on(dispatchSearch, (state: SearchResult): SearchResult => {
        return {
            ...state,
            isLoading: true,
            isCanceled: false,
            isLoadingNextPage: false,
            isBeforeFirstSearch: false,
            displaySavedSearch: true,
            hasError: false,
        };
    }),
    on(dispatchEmptySearch, (state: SearchResult): SearchResult => {
        return {
            ...state,
            isLoading: false,
            isCanceled: false,
            isLoadingNextPage: false,
            isBeforeFirstSearch: true,
            displaySavedSearch: false,
            hasError: false,
        };
    }),
    on(dispatchEmptySearchAfterTypeChange, (state: SearchResult): SearchResult => {
        return {
            ...state,
            isLoading: false,
            isCanceled: false,
            isLoadingNextPage: false,
            isBeforeFirstSearch: false,
            displaySavedSearch: true,
            hasError: false,
        };
    }),
    on(dispatchSearchNextPage, (state: SearchResult): SearchResult => {
        return {
            ...state,
            isLoading: false,
            isCanceled: false,
            isLoadingNextPage: true,
            isBeforeFirstSearch: false,
            displaySavedSearch: true,
            hasError: false,
        };
    }),
    on(cancelSearch, (state: SearchResult): SearchResult => {
        return { ...state, isCanceled: true, isLoading: false };
    }),
    on(notifyOfSearchError, (state: SearchResult): SearchResult => {
        return { ...state, isLoading: false };
    }),
    on(setSearchResponse, (state: SearchResult, { searchResponse }): SearchResult => {
        const existingHits = searchResponse.hitList?.currentPage !== 1 ? state.lastResponse?.hitList?.hits || [] : [];
        const newHits = searchResponse?.hitList?.hits?.map((hit, ix) => ({ ...hit, indexOnPage: ix })) || [];
        const allHits = [...existingHits, ...newHits];
        return {
            ...state,
            isLoading: false,
            hasError: false,
            isLoadingNextPage: false,
            isCanceled: false,
            lastResponse: { ...searchResponse, hitList: { ...searchResponse?.hitList, hits: allHits } },
        };
    }),
    on(setScrollTopPosition, (state: SearchResult, { scrollTopPosition }): SearchResult => {
        return {
            ...state,
            scrollTopPosition: scrollTopPosition,
        };
    }),
    on(updateFacetSearchInput, (state: SearchResult, { facetField }) => {
        const isLoadingFacetUpdated = new Map(state.isLoadingFacet);
        isLoadingFacetUpdated.set(facetField, true);
        return {
            ...state,
            isLoadingFacet: isLoadingFacetUpdated,
        };
    }),
    on(setFacetSearchResponse, (state: SearchResult, { facetSearchResponse }): SearchResult => {
        const facetField = facetSearchResponse.facet?.field;
        const isLoadingFacetUpdated = new Map(state.isLoadingFacet);
        if (facetField) {
            isLoadingFacetUpdated.set(facetField, false);
        }

        const facets = state.lastResponse?.facets?.filter(f => f.field !== facetField);
        if (facets && facetSearchResponse.facet && state.lastResponse) {
            facets.push(facetSearchResponse.facet);
            return {
                ...state,
                lastResponse: {
                    ...state.lastResponse,
                    facets: facets,
                },
                isLoadingFacet: isLoadingFacetUpdated,
            };
        }
        return state;
    }),
    on(setFacetsOnly, (state: SearchResult, { searchResponse }): SearchResult => {
        return {
            ...state,
            lastResponse: {
                ...state.lastResponse!,
                facets: searchResponse.facets,
            },
        };
    }),
    on(resetSearch, (state: SearchResult): SearchResult => {
        return {
            ...state,
            displaySavedSearch: false,
        };
    })
);
