<div class="container">
    <div class="header-title" tabindex="0" role="button" (keydown)="goHomeKeyEvent($event)" (click)="goHome()" aria-label="Zur Startseite" data-no-search>
        <img class="logo" src="assets/images/logo_srf.svg" alt="SRF Logo" />
        <div>
            <h1>Mediendatenbank FARO</h1>
        </div>
    </div>
    <app-header-banner *ngIf="text$ | async as text" class="header-banner" [warningText]="text"></app-header-banner>
    <app-header-actions *ngIf="isUserAuthenticated$ | async"></app-header-actions>
</div>
