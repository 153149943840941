import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, map, filter } from 'rxjs';
import { BasicUserInformation } from '../state/user.state';

@Injectable()
export class UserService {
    constructor(private readonly securityService: OidcSecurityService) {}

    userInformation$: Observable<BasicUserInformation> = this.securityService.userData$.pipe(filter(v => !!v.userData)).pipe(
        map(x => {
            if (!x.userData.faro_userid) {
                throw new Error('Expected faro_userid in id_token');
            }
            return {
                id: x.userData.faro_userid,
                name: x.userData.name,
                eMail: x.userData.sub,
            };
        })
    );
}
