<ng-container *ngIf="avComponent$ | async as avComponent">
    <p-splitter
        *ngrxLet="nav$; let nav"
        styleClass="splitter"
        stateStorage="{{ splitterConfig.storageLocation }}"
        stateKey="{{ splitterConfig.storageKey }}"
    >
        <ng-template pTemplate="tab-view">
            <div class="tab-view-split-part">
                <p-tabView [activeIndex]="nav?.hasSubtitlesOrTranscripts && !nav?.hasSequences ? 1 : 0">
                    <p-tabPanel>
                        <ng-template pTemplate="header">Sequenzen</ng-template>
                        <ng-template pTemplate="content">
                            <app-sequence-content
                                [readonly]="readonly"
                                [contentType]="avComponent.type"
                                [programId]="avComponent.program"
                                [itemId]="avComponent.item"
                                [keywords]="keywords"
                            ></app-sequence-content>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">Untertitel/Transkript</ng-template>
                        <ng-template pTemplate="content">
                            <app-subtitle-transcript-content
                                [readonly]="readonly"
                                [contentType]="avComponent.type"
                                [programId]="avComponent.program"
                                [itemId]="avComponent.item"
                                [keywords]="keywords"
                            ></app-subtitle-transcript-content>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            Information
                            <div
                                class="right-restriction-marker"
                                *ngIf="nav?.hasUsageConstraints"
                                aria-label="Rechtehinweise vorhanden"
                                data-cy="right-restriction-marker"
                        >(Beitragsrechte beachten!)</div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <app-information-content
                                [contentType]="avComponent.type"
                                [programId]="avComponent.program"
                                [itemId]="avComponent.item"
                                [keywords]="keywords"
                            ></app-information-content>
                        </ng-template>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </ng-template>
        <ng-template pTemplate="player-view">
            <div class="player-split-part" *ngIf="mediaInformation$ | async as mediaInformation">
                <h2 class="h-offscreen">Player</h2>
                <ng-container *ngrxLet="playerMediaCut$; let mediaCut">
                    <ng-container *ngIf="mediaInformation.isAudioProgram">
                        <div class="player-container">
                            <app-restriction-bar
                                aria-live="assertive"
                                [restrictions]="nav?.restrictions || []"
                            ></app-restriction-bar>
                            <app-envelope
                                *ngIf="mediaInformation.isAudioProgram && mediaCut"
                                [programId]="avComponent.program"
                                [itemId]="avComponent.item"
                                [mediaCutVtcIn]="mediaCut.vtcIn"
                                [mediaCutVtcOut]="mediaCut.vtcOut"
                                [mediaCutId]="mediaCut.mediaCutId"
                                [markerVtcIn]="(vtcIn$ | async) ?? undefined"
                                [markerVtcOut]="(vtcOut$ | async) ?? undefined"
                            ></app-envelope>
                            <app-sections-bar
                                class="audio-sequence-bar"
                                *ngIf="mediaInformation.isAudioProgram && mediaCut"
                                [sections]="
                                avComponent.type === 'item'
                                    ? (sectionInformation$ | async) ?? []
                                    : (programSectionInformation$ | async) ?? []
                            "
                                [vtcIn]="mediaCut.vtcIn"
                                [vtcOut]="mediaCut.vtcOut"
                                [displayString]="avComponent.type === 'item' ? 'S' : 'B'"
                                (sectionClicked)="jumpToVtcIn($event)"
                            ></app-sections-bar>
                            <app-sections-bar
                                class="audio-sequence-bar"
                                *ngIf="mediaInformation.isAudioProgram && mediaCut && avComponent.type === 'program'"
                                [sections]="(programSequenceSectionInformation$ | async) ?? []"
                                [vtcIn]="mediaCut.vtcIn"
                                [vtcOut]="mediaCut.vtcOut"
                                [displayString]="'S'"
                                (sectionClicked)="jumpToVtcIn($event)"
                            ></app-sections-bar>
                            <app-player
                                #flowPlayer
                                [media]="mediaInformation"
                                [restrictions]="nav?.restrictions || []"
                                [markerVtcIn]="(vtcIn$ | async) ?? undefined"
                                [markerVtcOut]="(vtcOut$ | async) ?? undefined"
                                [previousPlayerState]="previousPlayerState$ | async"
                                (mediaCutRequested)="selectMedia($event)"
                                (audioTrackRequested)="setSelectedAudioTrack($event)"
                                (mediaCutLoaded)="onMediaCutLoaded($event)"
                                (playingStarted)="onPlayingStarted()"
                                (playState)="updatePlayState($event)"
                            ></app-player>
                        </div>
                        <div class="in-out-main" #inOutElement>
                            <app-in-out-time-code [readonly]="readonly"></app-in-out-time-code>
                            <div class="in-out-group">
                                <ng-container *ngrxLet="isInOutSelectionValid$; let isInOutSelectionValid">
                                    <button
                                        *ngIf="!readonly"
                                        pRipple
                                        title="Mit Vermerk bestellen (v)"
                                        type="button"
                                        class="order-remark-button"
                                        id="order-with-remark-player-button"
                                        (click)="orderInOutSelectionWithRemark()"
                                        [disabled]="!isInOutSelectionValid"
                                    >
                                        <img src="assets/icons/order-with-remark.svg" alt="" />
                                    </button>
                                    <button
                                        *ngIf="!readonly"
                                        pRipple
                                        title="In den Warenkorb (w)"
                                        class="search-button player-search-button"
                                        [ngClass]="{'search-button-icon-only' : hideButtonText}"
                                        type="button"
                                        (click)="orderInOutSelection()"
                                        [disabled]="!isInOutSelectionValid"
                                    >
                                        <img src="assets/icons/shopping-cart-white.svg" alt="" /><span *ngIf="!hideButtonText">In den Warenkorb</span>
                                    </button>
                                    <button
                                        *ngIf="readonly"
                                        pRipple
                                        class="search-button order-search-button"
                                        [ngClass]="{'search-button-icon-only' : hideButtonText}"
                                        type="button"
                                        (click)="goToDetails(avComponent.program, avComponent.item)"
                                    >
                                        <img src="assets/icons/arrow-top-right-white.svg" alt="" />
                                        <span *ngIf="!hideButtonText">{{ avComponent.item ? "Zum Beitrag" : "Zur Sendung" }}</span>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!mediaInformation.isAudioProgram">
                        <p-splitter
                            styleClass="splitter-vertical"
                            layout="vertical"
                            stateStorage="{{ verticalSplitterConfig.storageLocation }}"
                            stateKey="{{ verticalSplitterConfig.storageKey }}"
                        >
                            <ng-template pTemplate="player-vertical-view">
                                <div class="player-vertical-split-part">
                                    <div class="player-container player-vertical-container">
                                        <app-restriction-bar
                                            aria-live="assertive"
                                            [restrictions]="nav?.restrictions || []"
                                        ></app-restriction-bar>
                                        <app-player
                                            #flowPlayer
                                            [media]="mediaInformation"
                                            [restrictions]="nav?.restrictions || []"
                                            [markerVtcIn]="(vtcIn$ | async) ?? undefined"
                                            [markerVtcOut]="(vtcOut$ | async) ?? undefined"
                                            [previousPlayerState]="previousPlayerState$ | async"
                                            (mediaCutRequested)="selectMedia($event)"
                                            (audioTrackRequested)="setSelectedAudioTrack($event)"
                                            (mediaCutLoaded)="onMediaCutLoaded($event)"
                                            (playingStarted)="onPlayingStarted()"
                                            (playState)="updatePlayState($event)"
                                        ></app-player>
                                    </div>
                                    <div class="in-out-main in-out-bottom" #inOutElement>
                                        <app-in-out-time-code [readonly]="readonly"></app-in-out-time-code>
                                        <div class="in-out-group">
                                            <ng-container *ngrxLet="isInOutSelectionValid$; let isInOutSelectionValid">
                                                <button
                                                    *ngIf="!readonly"
                                                    pRipple
                                                    title="Mit Vermerk bestellen (v)"
                                                    type="button"
                                                    class="order-remark-button"
                                                    id="order-with-remark-player-button"
                                                    (click)="orderInOutSelectionWithRemark()"
                                                    [disabled]="!isInOutSelectionValid"
                                                >
                                                    <img src="assets/icons/order-with-remark.svg" alt="" />
                                                </button>
                                                <button
                                                    *ngIf="!readonly"
                                                    pRipple
                                                    title="In den Warenkorb (w)"
                                                    class="search-button player-search-button"
                                                    [ngClass]="{'search-button-icon-only' : hideButtonText}"
                                                    type="button"
                                                    (click)="orderInOutSelection()"
                                                    [disabled]="!isInOutSelectionValid"
                                                >
                                                    <img src="assets/icons/shopping-cart-white.svg" alt="" /><span *ngIf="!hideButtonText">In den Warenkorb</span>
                                                </button>
                                                <button
                                                    *ngIf="readonly"
                                                    pRipple
                                                    class="search-button order-search-button"
                                                    [ngClass]="{'search-button-icon-only' : hideButtonText}"
                                                    type="button"
                                                    (click)="goToDetails(avComponent.program, avComponent.item)"
                                                >
                                                    <img src="assets/icons/arrow-top-right-white.svg" alt="" />
                                                    <span *ngIf="!hideButtonText">{{ avComponent.item ? "Zum Beitrag" : "Zur Sendung" }}</span>
                                                </button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="light-table-vertical-view">
                                <div class="light-table">
                                        <app-light-table-view
                                            #lightTableView
                                            *ngIf="!mediaInformation.isAudioProgram"
                                            [contentType]="avComponent.type"
                                            [programId]="avComponent.program"
                                            [itemId]="avComponent.item"
                                            [mediaCut]="mediaCut"
                                            [aspectRatio]="mediaInformation.pictureAspectRatio"
                                        ></app-light-table-view>
                                </div>
                            </ng-template>
                        </p-splitter>
                    </ng-container>
                </ng-container>
            </div>
        </ng-template>
    </p-splitter>
</ng-container>
