<div class="dialog-content" aria-labelledby="dialogTitle" aria-modal="true">
    <div class="dialog-header">
        <div class="title" id="dialogTitle">Rechte</div>
    </div>
    <div>
        <table>
            <tr>
                <td class="rights-title">Rechte</td>
                <td [innerHTML]="restrictions?.rights ?? '' | unsanitizedHtml"></td>
            </tr>
            <tr>
                <td class="rights-title">Rechteinhaber</td>
                <td [innerHTML]="restrictions?.licenseHolder ?? '' | unsanitizedHtml"></td>
            </tr>
            <tr>
                <td class="rights-title">Verwendung</td>
                <td [innerHTML]="restrictions?.usageConstraints ?? '' | unsanitizedHtml"></td>
            </tr>
            <tr>
                <td class="rights-title">Verwendungshinweis</td>
                <td [innerHTML]="restrictions?.usageConstraintRemark ?? '' | unsanitizedHtml"></td>
            </tr>
        </table>
    </div>
</div>
