import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ProfileFiltersComponent } from 'src/app/search/search-input/profile-filters/profile-filters.component';
import { selectSearchProfileDefault } from 'src/app/search/search-state/search-profile.selectors';
import { dispatchSearchNextPage, setScrollTopPosition } from 'src/app/search/search-state/search-result.actions';
import {
    selectCurrentPage,
    selectDisplaySavedSearch,
    selectIsLoading,
    selectIsLoadingNextPage,
    selectScrollTopPosition,
    selectSearchCanceled,
    selectTotalPageCount,
} from 'src/app/search/search-state/search-result.selectors';
import { ConfirmationDialogService } from '../../../../shared/services/confirmation-dialog.service';
import { TableHitComponentModel } from './table-hit-component.model';
import {
    OrderRemarkDialogContentAndConfig,
    OrderRemarkResponse,
} from '../../../../shared/dialogs/order-remark-dialog/order-remark-dialog-content-and.config';
import { focusElement } from '../../../../shared/focus-element.helper';
import { AddShoppingCartEntryRequestDto } from '@faro/order-angular-client';
import { setCurrentHitId } from '../../../../state/shared.actions';
import { SearchFieldSelectionEnum } from '../../../search-state/search-options.state';
import { selectSearchFieldSelection } from '../../../search-state/search-options.selectors';
import { ActionProviderService } from 'src/app/shared/services/action-provider.service';
import { EntityInfo, HostConnectorService } from 'src/app/shared/services/host-connector.service';
import { selectCanUseDocSet, selectCanUseShoppingCart, selectCanViewCataloging } from 'src/app/state/user.selectors';

@Component({
    selector: 'app-table-hitlist',
    templateUrl: './table-hitlist.component.html',
    styleUrls: ['./table-hitlist.component.scss'],
})
export class TableHitlistComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    // This prevents double-clicks from selecting text or setting text marks, which was an annoying side-effect in the embedded mode
    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        if (event.detail > 1) {
            event.preventDefault();
        }
    }
  
    @Input()
    hitlist: TableHitComponentModel[] | null = [];

    @Input()
    isLoadingNextPageIndicator: boolean = false;

    @Input()
    isSearchRestricted: boolean = false;

    @ViewChild('scrollTable')
    private scrollTable: Table | undefined;

    private unlistenMouseMove: () => void = () => {};

    highlightedRowIndex: number | undefined;
    areProfileFiltersSet$: Observable<boolean | undefined>;
    displaySavedSearch: boolean = false;
    scrollTopPosition: number = 0;
    isLoading$: Observable<boolean>;
    tableRows: TableHitComponentModel[] = [];
    selectedSearchType = SearchFieldSelectionEnum.TOPIC;
    canUseDocSet: boolean = false;
    canUseShoppingCart: boolean = false;
    canViewCataloging: boolean = false;

    private isLoadingNextPage: boolean = false;
    private isCanceled: boolean = false;
    private currentPage: number | undefined = 1;
    private maxPage: number | undefined = 0;
    private pixelToReload: number = 3000;
    private currentTooltipHeight: number = 0;

    tableHeader = [
        { value: 'Medien', scssClass: 'media-header' },
        { value: 'Sendung', scssClass: 'program-header' },
        { value: 'Datum', scssClass: 'date-header' },
        { value: 'Beitragstitel', scssClass: 'title-header' },
        {
            value: 'Thema',
            scssClass: 'topic-header',
        },
        { value: 'Quelle/Rechte', scssClass: 'source-header' },
    ];

    private _destroyed$ = new Subject<void>();

    get isAppInWebViewContainer(): boolean {
        return this.hostConnector.isAppInWebViewContainer();
    };

    constructor(
        private readonly store: Store,
        private readonly dialogService: DialogService,
        private readonly actionProviderService: ActionProviderService,
        private readonly confirmationDialogService: ConfirmationDialogService,
        private zone: NgZone,
        private renderer: Renderer2,
        private readonly hostConnector: HostConnectorService
    ) {
        this.areProfileFiltersSet$ = this.store.select(selectSearchProfileDefault);
        this.isLoading$ = this.store.select(selectIsLoading);
        this.store
            .select(selectSearchFieldSelection)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.selectedSearchType = data.value;
            });
    }

    ngOnInit(): void {
        this.store
            .select(selectCurrentPage)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.currentPage = data;
            });

        this.store
            .select(selectTotalPageCount)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.maxPage = data;
            });

        this.store
            .select(selectIsLoadingNextPage)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.isLoadingNextPage = data;
            });
        this.store
            .select(selectSearchCanceled)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.isCanceled = data;
            });
        this.store
            .select(selectDisplaySavedSearch)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.displaySavedSearch = data;
            });
        this.store
            .select(selectScrollTopPosition)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.scrollTopPosition = data;
            });
        this.store
            .select(selectCanUseDocSet)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.canUseDocSet = data;
            });
        this.store
            .select(selectCanUseShoppingCart)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.canUseShoppingCart = data;
            });
        this.store
            .select(selectCanViewCataloging)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.canViewCataloging = data;
            });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        this.unlistenMouseMove();
    }

    ngAfterViewInit() {
        const $scrollTable = this.scrollTable?.el.nativeElement as HTMLElement;
        const $scrollElement = $scrollTable?.querySelector<HTMLElement>('div.p-datatable-wrapper');
        if ($scrollElement) {
            $scrollElement.onscroll = () => {
                if ($scrollElement.scrollHeight - $scrollElement.scrollTop < this.pixelToReload) {
                    if (this.currentPage && this.maxPage) {
                        if (!this.isLoadingNextPage && this.currentPage < this.maxPage) {
                            this.loadMore();
                        }
                    }
                }
            };
        }
        this.store
            .select(selectIsLoading)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                if (!data && !this.isCanceled) {
                    if ($scrollElement) {
                        $scrollElement.scroll(0, 0);
                    }
                }
            });

        if (this.displaySavedSearch && $scrollElement) {
            $scrollElement.scroll(0, this.scrollTopPosition);
        }

        /**
         * Makes sure that the tooltip appears next to the mouse pointer
         * */
        this.zone.runOutsideAngular(() => {
            this.unlistenMouseMove = this.renderer.listen(document, 'mousemove', event => {
                let x = event.clientX + 10;
                let y = event.clientY + 10;
                let tooltip = document.querySelector<HTMLElement>('.table-tooltip');
                if (tooltip) {
                    this.setTooltipPosition(tooltip, x, y);
                }
            });
        });
    }

    setTooltipPosition(tooltip: HTMLElement, x: number, y: number): void {
        if (tooltip.offsetHeight > 0) {
            this.currentTooltipHeight = tooltip.offsetHeight;
        }
        tooltip.style.visibility = 'hidden';

        if (y + this.currentTooltipHeight >= window.innerHeight) {
            y = window.innerHeight - (this.currentTooltipHeight + tooltipMarginRight);
        }

        if (x + tooltipWidth >= window.innerWidth) {
            x = window.innerWidth - (tooltipWidth + tooltipMarginRight);
        }

        tooltip!.style.left = x + 'px';
        tooltip!.style.top = y + 'px';
        setTimeout(() => {
            if (tooltip) {
                tooltip.style.visibility = 'visible';
            }
        }, tooltipTimeout);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['hitlist'] || changes['isLoadingNextPage'] || changes['isSearchRestricted']) {
            // recreate tableRows
            this.tableRows = this.hitlist || [];
            if (this.tableRows) {
                if (this.isLoadingNextPageIndicator) {
                    this.tableRows.push({ isLoadingIndicator: true });
                }
                if (this.isSearchRestricted) {
                    this.tableRows.push({ isSearchRestricted: true });
                }
            }
        }
        if (changes['hitlist']) {
            const messageElement = document.getElementById('table-update-message');
            if (messageElement) {
                messageElement.textContent = '';
                setTimeout(() => {
                    messageElement.textContent = 'Die Tabelle wurde neu geladen.';
                }, 100);
            }
        }
        this.resetTableHeader();
    }

    minTableRowsReached(): boolean {
        if (this.tableRows) {
            return this.tableRows?.length > 0 && this.tableRows?.length <= 6;
        } else {
            return false;
        }
    }

    showActions(event: Event, index: number): void {
        /**
         * Timeout prevents search when working with keyboard
         * */
        setTimeout(() => {
            this.highlightedRowIndex = index;
        }, 100);
        /**
         * Timeout to make sure buttons are in DOM
         * */
        setTimeout(() => {
            if (document.getElementById(`order-with-remark-${index}`)) {
                focusElement(`order-with-remark-${index}`);
            }
        }, 100);
        event.stopPropagation();
    }

    async goToDetails(event: MouseEvent, hit: TableHitComponentModel) {
        event.preventDefault();
        event.stopPropagation();
        const $scrollTable = this.scrollTable?.el.nativeElement as HTMLElement;
        const $scrollElement = $scrollTable?.querySelector<HTMLElement>('div.p-datatable-wrapper');
        this.store.dispatch(setScrollTopPosition({ scrollTopPosition: $scrollElement?.scrollTop || 0 }));
        this.store.dispatch(setCurrentHitId({ hitId: hit.hitId }));

        const target = event.target as HTMLElement;
        const imageClicked = Boolean(target.closest('.hit-list-image'));

        return this.actionProviderService.dispatchDetailAction({ programId: hit.programId!, itemId: hit.itemId ?? hit.hitId, sequenceId: hit.itemId ? hit.hitId : undefined }, this.canViewCataloging, event.type === 'dblclick' && event.shiftKey);
    }

    orderWithRemark(event: Event, hit: TableHitComponentModel, id: string) {
        event.stopPropagation();
        const selectedContent: OrderRemarkDialogContentAndConfig = {
            displayCheckBox: false,
        };
        this.confirmationDialogService
            .openRemarkDialog(selectedContent)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: OrderRemarkResponse) => {
                if (data) {
                    const item: AddShoppingCartEntryRequestDto = {
                        itemId: hit.itemId ? hit.itemId : hit.hitId,
                        programId: hit.programId!,
                        sequenceId: hit.itemId ? hit.hitId : null,
                        ignoreUserRestrictions: false,
                        remark: data.orderRemark,
                        ignoreSizeLimit: false,
                    };
                    this.actionProviderService.dispatchShoppingCartAction(item);
                }
                focusElement(id);
            });
    }

    addToShoppingCart(event: Event, hit: TableHitComponentModel, id: string) {
        event.stopPropagation();
        const item: AddShoppingCartEntryRequestDto = {
            itemId: hit.itemId ? hit.itemId : hit.hitId,
            programId: hit.programId!,
            sequenceId: hit.itemId ? hit.hitId : null,
            ignoreUserRestrictions: false,
            ignoreSizeLimit: false,
        };
        this.actionProviderService.dispatchShoppingCartAction(item);
        focusElement(id);
    }

    addToDocSet(event: Event, hit: TableHitComponentModel, id: string) {
        event.stopPropagation();
        const item: EntityInfo = {
            itemId: hit.itemId ?? hit.hitId,
            programId: hit.programId!,
            sequenceId: hit.itemId ? hit.hitId : undefined,
        };
        this.actionProviderService.dispatchDocSetAction(item);
        focusElement(id);
    }

    loadMore() {
        if (this.currentPage) {
            this.store.dispatch(dispatchSearchNextPage());
        }
    }

    openProfileFiltersDialog(): void {
        this.dialogService.open(ProfileFiltersComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'profile-dialog',
            data: { title: 'Sucheinstellungen' },
        });
    }

    resetTableHeader(): void {
        this.tableHeader = [
            { value: 'Medien', scssClass: 'media-header' },
            { value: 'Sendung', scssClass: 'program-header' },
            { value: 'Datum', scssClass: 'date-header' },
            { value: 'Beitragstitel', scssClass: 'title-header' },
            {
                value: this.selectedSearchType === SearchFieldSelectionEnum.IMAGE ? 'Beschreibung' : 'Thema',
                scssClass: 'topic-header',
            },
            { value: 'Quelle/Rechte', scssClass: 'source-header' },
        ];
    }

    showConstraintTooltipLink(hit: TableHitComponentModel): boolean {
        return hit.restrictions !== undefined &&
            Boolean(hit.restrictions.rights || hit.restrictions.licenseHolder || hit.restrictions.usageConstraints || hit.restrictions.usageConstraintRemark);
    }
}

const tooltipTimeout = 1000;
const tooltipWidth = 653;
const tooltipMarginRight = 25;
