<div class="shopping-cart-content-header">
    <h2 class="shopping-cart-content-header-title" *ngIf="hasEntries && !loading">{{ entryList.length }} Einträge</h2>
    <h2 class="shopping-cart-content-header-title" *ngIf="loading">Warenkorb wird geladen</h2>
</div>
<div class="shopping-cart-entries-container" [hidden]="!hasEntries">
    <div>
        <p-table
            [value]="entryList"
            [columns]="cols"
            responsiveLayout="scroll"
            scrollHeight="var(--shopping-cart-table-scroll-height)"
            [scrollable]="true"
            [autoLayout]="true"
            (onRowReorder)="rowReordered()"
            #scrollTable
        >
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th class="reorder-header" *ngIf="!readonly">
                        <span class="h-offscreen">Einträge mittels drag and drop sortieren</span>
                    </th>
                    <th *ngFor="let col of columns" class="{{ col.scssHeaderClass }}" [attr.aria-label]="col.header">
                        <span class="h-offscreen">Spaltentitel</span>{{ col.header }}
                    </th>
                    <th class="action-header" *ngIf="!readonly" aria-label="Eintrags Aktionen"><span class="h-offscreen">Spaltentitel Eintrags Aktionen</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-columns="columns">
                <tr
                    [id]="rowData.id"
                    [appHighlightRow]="index"
                    (highlightedRowChanged)="highlightedRowIndex = $event"
                    [pReorderableRow]="!readonly ? index : undefined"
                    (click)="goToDetailView(rowData['id'])"
                    class="table-row"
                    [ngClass]="{ 'restricted-entry': rowData.entryError }"
                >
                    <td class="reorder-icon" *ngIf="!readonly">
                        <span class="reorder-table-icon" aria-hidden="true" pReorderableRowHandle></span>
                    </td>
                    <td *ngFor="let col of columns" class="{{ col.scssColumnClass }}">
                        <ng-container *ngIf="col.type === 'image'">
                            <img
                                [src]="
                                    ($any(rowData.keyframeBlob$ | async) | blobToObjectURL) ??
                                    'assets/images/empty-keyframe.svg'
                                "
                                alt="Warenkorbeintrag {{ index + 1 }}"
                            />
                            <div class="table-entry-number" *ngIf="highlightedRowIndex === index">{{ index + 1 }}</div>
                            <div class="h-offscreen">
                                Laufzeit {{ getAccessibilityDurationString(rowData["length"]) }}
                            </div>
                            <div class="entry-length" aria-hidden="true">
                                {{ rowData["length"] ? (rowData["length"] | durationFormatPipe) : "-" }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="col.type === 'text'">
                            <div class="edit-container material-edit-container" data-cy="material-edit-container">
                                <ng-container *ngIf="readonly">
                                    <span *ngFor="let material of rowData[col.field]" class="material edit-label">
                                        {{ material }}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!readonly">
                                    <span
                                        *ngFor="let material of rowData[col.field]"
                                        class="material edit-label"
                                        (click)="openMediaSelection($event, rowData)"
                                    >
                                        {{ material }}
                                    </span>
                                    <span
                                        *ngIf="rowData[col.field].length === 0"
                                        (click)="openMediaSelection($event, rowData)"
                                        class="no-material-entry"
                                    >
                                        <img src="assets/icons/arrow-top-right.svg" alt="" class="format-selection-arrow" />Material/Format wählen
                                    </span>
                                    <button
                                        *ngIf="highlightedRowIndex === index"
                                        class="edit-button"
                                        title="Material auswählen"
                                        (click)="openMediaSelection($event, rowData)"
                                        data-cy="material-edit-button"
                                    >
                                        <img class="image" src="assets/icons/edit.svg" alt="" />
                                    </button>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="col.type === 'item'">
                            <a
                                (click)="$event.preventDefault()"
                                class="accessibility-link"
                                (focus)="showActions($event, index)"
                                [href]="
                                    isActiveCart
                                        ? 'shopping-cart/active/' + rowData['id']
                                        : 'shopping-cart/preview/' + cartId + '/' + rowData['id']
                                "
                                >{{ rowData[col.field] }}</a
                            >
                        </ng-container>
                        <ng-container *ngIf="col.type === 'inline-edit'">
                            <div class="edit-container remark-edit-container" data-cy="remark-edit-container">
                                <span *ngIf="isDisplayVisible(index)" class="edit-label">
                                    {{ rowData[col.field] }}
                                </span>
                                <faro-inline-edit-field
                                    *ngIf="isInlineVisible(index)"
                                    [iconAltText]="'Vermerk ändern'"
                                    [placeholder]="'Text eingeben'"
                                    [displayString]="rowData[col.field]"
                                    (displayStringChanged)="onEntryNoteChanged($event, rowData.id, index)"
                                    (inputFieldFocused)="setFocus(index)"
                                    (lostFocus)="resetFocus()"
                                    [iconPlacementRight]="true"
                                ></faro-inline-edit-field>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="col.type === 'icon' && hasConstraints(rowData)">
                            <img
                                src="assets/icons/warning-triangle.svg"
                                alt="Rechtehinweis"
                                tooltipStyleClass="entries-table-tooltip"
                                [pTooltip]="rowData['rightsTooltip']"
                                [escape]="false"
                            />
                            <span class="h-offscreen">{{ rowData["offsetTooltip"] }}</span>
                        </ng-container>
                    </td>
                    <td class="action-icon-td" *ngIf="!readonly" (click)="showActions($event, index)">
                        <button
                            type="button"
                            class="p-table-icon-button"
                            *ngIf="highlightedRowIndex !== index"
                            (click)="showActions($event, index)"
                        >
                            <img src="assets/icons/ellipsis.svg" alt="Optionen" />
                        </button>
                    </td>
                    <div
                        class="p-table-action-button-container delete-container"
                        *ngIf="!readonly && highlightedRowIndex === index"
                    >
                        <button
                            title="Eintrag löschen"
                            pRipple
                            type="button"
                            class="p-table-action-button single-button"
                            id="{{ 'delete-entry-' + index }}"
                            (click)="deleteEntry($event, rowData)"
                            data-cy="delete-shopping-cart-entry-table-button"
                        >
                            <img src="assets/icons/trash-white.svg" alt="" />
                        </button>
                    </div>
                </tr>
                <tr *ngIf="rowData.entryError" class="error-message-row">
                    <td colspan="7" class="error-message-data" aria-hidden="true">{{ rowData.entryError }}</td>
                    <td
                        class="h-offscreen"
                        role="alert"
                        [attr.aria-label]="'Eintrag ' + (index + 1) + rowData.entryError"
                    ></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<ng-container *ngIf="!hasEntries && !loading">
    <app-empty-content [infoText]="'keine Einträge im Warenkorb'"></app-empty-content>
</ng-container>
<ng-container *ngIf="loading">
    <div class="loading-container">
        <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
    </div>
</ng-container>
