import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { ListFacetComponentModel, ListFacetItemComponentModel } from './list-facet.interfaces';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';

@Component({
    selector: 'faro-list-facet',
    templateUrl: './list-facet.component.html',
    styleUrls: ['./list-facet.component.scss'],
})
export class ListFacetComponent implements OnDestroy {
    @Input()
    header?: string;

    @Input()
    hideSearch: boolean = false;

    @Input()
    placeholderText: string = '';

    @Input()
    set query(text: string | null) {
        this._searchTerm = text ?? '';
    }

    @Input()
    collapsed: boolean = true;

    @Input()
    facet: ListFacetComponentModel | null = null;

    @Input()
    showDeleteIcon: boolean = false;

    @Input()
    headerIconAriaLabel: string = '';

    @Input()
    displayAndOrOperatorSwitch: boolean = true;

    @Input()
    set searchFieldAlwaysVisible(value: boolean) {
        this.searchFieldHidden = false;
        this.alwaysDisplaySearchField = value;
    }

    private _facetField: string | undefined;
    private _countLabel: number | null = 0;

    @Input()
    set facetField(field: string | undefined) {
        if (this._facetField !== field) {
            this._facetField = field;
        }
    }

    @Input()
    isLoading: boolean | null = false;

    @Input()
    set searchOperators(value: { label: string; value: string }[]) {
        this.availableSearchOperators = value.length > 0 ? value : [];
    }

    @Input()
    set selectedSearchOperatorValue(value: string) {
        this.selectedSearchOperator = value;
    }

    @Input()
    set panelCountLabel(countLabel: number | null) {
        this._countLabel = countLabel;
    }

    get panelCountLabel(): number {
        if (!this._countLabel) return 0;
        return this._countLabel;
    }

    @Output()
    checkBoxChanged = new EventEmitter<ListFacetItemComponentModel>();

    @Output()
    searchTermChanged = new EventEmitter<{ facetField: string; query: string }>();

    @Output()
    deleteIconClicked = new EventEmitter();

    @Output()
    collapsedStateChanged = new EventEmitter<boolean>();

    @Output()
    facetSearchOperatorChanged = new EventEmitter<string>();

    private static _seq: number = 0;
    public listFacetComponentId: string = 'list_facet_input_' + ListFacetComponent._seq++;

    searchFieldHidden = true;
    alwaysDisplaySearchField = false;

    availableSearchOperators: { label: string; value: string }[] = [];
    selectedSearchOperator: string = '';

    get showNoHitsHint(): boolean {
        return (
            this.isLoading === false &&
            (this.facet?.dynamicItems.length ?? 0) === 0 &&
            (this.facet?.staticItems?.length ?? 0) === 0
        );
    }

    private _searchTerm = '';

    get searchTerm() {
        return this._searchTerm;
    }

    set searchTerm(v: string) {
        if (this._searchTerm !== v) {
            this._searchTerm = v;
            if (this._facetField) {
                this.searchTermChanged.emit({ facetField: this._facetField, query: this._searchTerm });
            }
        }
    }

    private unlistenInput: () => void = () => {};

    constructor(private renderer: Renderer2) {}

    ngOnDestroy() {
        this.unlistenInput();
    }

    onSearchIconClicked(): void {
        if (!this.alwaysDisplaySearchField) {
            if (this.searchFieldHidden) {
                this.searchFieldHidden = false;
                this.collapsed = false;
                setTimeout(() => {
                    const input = document.getElementById(this.listFacetComponentId);
                    input!.focus();

                    /**
                     * Do nothing when enter is pressed. Avoids search dispatch in SRF
                     * */
                    this.unlistenInput = this.renderer.listen(input, 'keyup', event => {
                        if (event.key === 'Enter') {
                            event.stopPropagation();
                        }
                    });
                }, 100);
            } else {
                if (this.collapsed) {
                    this.collapsed = false;
                } else {
                    this.searchFieldHidden = true;
                }
            }
        }
    }

    onDeleteIconClicked(): void {
        this.deleteIconClicked.emit('clicked');
        this.clearSearch();
    }

    itemStateChanged(item: ListFacetItemComponentModel): void {
        this.checkBoxChanged.emit(item);
    }

    clearSearch(): void {
        this.searchTerm = '';
        if (!this.alwaysDisplaySearchField) {
            this.searchFieldHidden = true;
        }
    }

    onCollapseIconClicked(collapsedState: boolean) {
        this.collapsed = collapsedState;
        if (collapsedState && this.searchTerm.length === 0 && !this.alwaysDisplaySearchField) {
            this.searchFieldHidden = true;
        }
        this.collapsedStateChanged.emit(collapsedState);
    }

    selectionChanged(event: SelectButtonChangeEvent) {
        this.facetSearchOperatorChanged.emit(event.value);
    }
}
