<div class="side-bar">
    <app-search-input></app-search-input>
    <div class="container" [ngClass]="{ 'container-collapsed': (showResetAllFiltersButton$ | async) === false }">
        <div class="filter-header">
            <div>
                <h2 class="title">Filter</h2>
            </div>
        </div>
        <p-scrollPanel #facetContainer class="facet-container"
            [ngClass]="{ 'facet-container-collapsed': (showResetAllFiltersButton$ | async) }">
            <ng-container>
                <div class="facet">
                    <ng-container *ngrxLet="dateRange$; let dateRange">
                        <faro-date-facet class="date-facet" [header]="'Datum'" [dateRange]="dateRange"
                            (changed)="onDateFacetChanged($event)" [showDeleteIcon]="true" [presets]="dateFacetPresets"
                            [showClose]="true" [applyCalendarSelectionInstantly]="true"
                            [collapsed]="dateFacetCollapsedState" [placeholderText]="'Datum eingeben'"
                            (dateFacetCollapsedChanged)="setDateFacetCollapsed($event)" [requiresEnterToSetDate]="true">
                        </faro-date-facet>
                    </ng-container>
                </div>
                <ng-container *ngFor="let key of facetsToDisplay">
                    <div [ngSwitch]="getFilterType(key)" class="facet">
                        <faro-list-facet *ngSwitchCase="'MatchFilter'" [id]="getFacetOptionsField(key)"
                            [header]="getFacetHeader(key)" [searchFieldAlwaysVisible]="true" [hideSearch]="true"
                            [facetField]="getFacetOptionsField(key)"
                            [query]="getInitialQuery(key) | async"
                            (searchTermChanged)="matchFilterQueryChanged($event)" [showDeleteIcon]="true"
                            [panelCountLabel]="getCountLabel(key) | async" [collapsed]="getFacetOptionsCollapsed(key)"
                            placeholderText="Suchen" (collapsedStateChanged)="setFacetCollapsed($event, key)"
                            headerIconAriaLabel="Suchen">
                        </faro-list-facet>
                        <ng-container *ngSwitchCase="'ValueFilter'">
                            <ng-container *ngrxLet="getObservable(key); let facet">
                                <faro-list-facet *ngIf="facet || isLoading" [id]="getFacetOptionsField(key)"
                                    [header]="getFacetHeader(key)" [searchFieldAlwaysVisible]="true" [hideSearch]="true"
                                    [facetField]="getFacetOptionsField(key)" [facet]="facet"
                                    [isLoading]="isLoading || getFacetOptionsLoading(key)"
                                    [searchOperators]="getFacetOptionsSearchModes(key)"
                                    [selectedSearchOperatorValue]="getSelectedFacetOperator(key)"
                                    (checkBoxChanged)="facetSelectionChanged($event)"
                                    (searchTermChanged)="facetQueryChanged($event)"
                                    (deleteIconClicked)="resetFacetSelections(getFacetOptionsField(key))"
                                    [showDeleteIcon]="true" [panelCountLabel]="getCountLabel(key) | async"
                                    [collapsed]="getFacetOptionsCollapsed(key)" placeholderText="Suchen"
                                    (collapsedStateChanged)="setFacetCollapsed($event, key)"
                                    (facetSearchOperatorChanged)="setFacetSearchMode($event, getFacetOptionsField(key))"
                                    headerIconAriaLabel="In Facette Suchen"></faro-list-facet>
                            </ng-container>
                        </ng-container>
                        <div *ngSwitchDefault>Unknown filter type</div>
                    </div>
                </ng-container>
            </ng-container>
        </p-scrollPanel>
    </div>
    <div class="search-actions">
        <div class="search-button-container" *ngrxLet="showResetAllFiltersButton$; let showResetAllFiltersButton">
            <button pRipple type="button" class="secondary-button" *ngIf="showResetAllFiltersButton"
                (click)="resetAllFilters()" data-cy="reset-all-filters-button">
                <img src="/assets/icons/trash.svg" alt="" />Alle Filter zurücksetzen
            </button>
            <button pRipple type="button" class="search-button" *ngIf="displaySearchButton$ | async"
                [ngClass]="{ 'filters-set': showResetAllFiltersButton }" (click)="search()" data-cy="search-button">
                <img src="/assets/icons/search-white.svg" alt="" />Suchen
            </button>
            <button pRipple type="button" class="search-button" *ngIf="displayUpdateSearchButton$ | async"
                [ngClass]="{ 'filters-set': showResetAllFiltersButton }" (click)="search()"
                data-cy="refresh-search-button">
                <img src="/assets/icons/sync-white.svg" alt="" />Suche aktualisieren
            </button>
        </div>
    </div>
</div>