import { createSelector } from '@ngrx/store';
import { SearchResult } from '../search-state/search-result.state';
import { Facet } from '@faro/searchapi-angular-client';
import { selectSearchResponse } from '../search-state/search-result.selectors';

export enum ItemFacetKeys {
    DESCRIPTOR = 'Item.DescriptorFacet.Term.keyword',
    PROGRAM = 'Item.Program.ProgramFacet.ProgramName.keyword',
    PEOPLE = 'Item.PeopleFacet.Term.keyword',
    SPORT = 'Item.SportDoc.Sports.SplitPath.Value.keyword',
    EVENT = 'Item.SportDoc.SportEvents.SplitPath.Value.keyword',
    YEAR = 'Item.SportDoc.Season.keyword',
    ORIGINATOR = 'Item.OriginatorFacet.Term.keyword',
    PRIORITY = 'Item.Sequences.Rating.Term.keyword',
    TEXTFIELDS = "TextFields",
    IMAGE_PROGRAM = 'Sequence.Item.Program.ProgramFacet.ProgramName.keyword',
    IMAGE_PEOPLE = 'Sequence.Item.PeopleFacet.Term.keyword',
    IMAGE_SPORT = 'Sequence.Item.SportDoc.Sports.SplitPath.Value.keyword',
    IMAGE_EVENT = 'Sequence.Item.SportDoc.SportEvents.SplitPath.Value.keyword',
    IMAGE_YEAR = 'Sequence.Item.SportDoc.Season.keyword',
    IMAGE_CONTENT = 'Sequence.ContentFacet.Value.keyword',
    IMAGE_CAMERA_SETTINGS = 'Sequence.CameraSettingsFacet.Value.keyword',
    IMAGE_COUNTRY = 'Sequence.Countries.SplitPath.Value.keyword',
    IMAGE_ORIGINATOR = 'Sequence.Item.OriginatorFacet.Term.keyword',
    IMAGE_PRIORITY = 'Sequence.Rating.Term.keyword',
}

function findFacetForKey(result: SearchResult, key: ItemFacetKeys): Facet | undefined {
    return result.lastResponse?.facets?.find((facet: Facet) => {
        return facet?.field === key;
    });
}

function createFacetSelector(key: ItemFacetKeys) {
    return createSelector(selectSearchResponse, (result: SearchResult): Facet | undefined => {
        return findFacetForKey(result, key);
    });
}

export const selectFacet = (key: ItemFacetKeys) => createFacetSelector(key);
