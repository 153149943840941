<div class="hit-list-dropdown-container">
    <p-dropdown
        aria-label="Sortieren"
        class="hit-list-dropdown"
        [options]="sortingByValues"
        [ngModel]="selectedSortingBy"
        optionLabel="label"
        (ngModelChange)="sortingByChanged($event)"
    ></p-dropdown>
    <p-selectButton
        class="hit-list-select"
        [options]="sortingOrderValues"
        [allowEmpty]="false"
        [ngModel]="selectedSortingOrder"
        (ngModelChange)="sortingOrderChanged($event)"
    >
        <ng-template let-item pTemplate>
            <i [class]="item.icon" [attr.aria-label]="item.ariaLabel"></i>
        </ng-template>
    </p-selectButton>
</div>
