import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchInfoDialogComponent } from '../search-input/info-dialog/search-info-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectActiveDisplaySearchInfoDialog } from '../search-state/search-options.selectors';
import { setSearchInfoDialogPreferences } from '../search-state/search-options.actions';
import { HostConnectorService } from 'src/app/shared/services/host-connector.service';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy {
    private _destroyed$ = new Subject<void>();
    private displayDialog: boolean = true;

    constructor(private dialogService: DialogService, private ref: DynamicDialogRef, private readonly store: Store, private readonly hostConnectorService: HostConnectorService) {
        this.store
            .select(selectActiveDisplaySearchInfoDialog)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.displayDialog = data && !this.hostConnectorService.isAppInWebViewContainer();
            });
    }

    ngOnInit(): void {
        if (this.displayDialog) {
            this.ref = this.dialogService.open(SearchInfoDialogComponent, {
                showHeader: false,
                closeOnEscape: true,
                dismissableMask: true,
                styleClass: 'info-dialog',
                data: { title: 'Suchinformationen', deactivatable: true },
            });

            this.ref.onClose
                .pipe(takeUntil(this._destroyed$))
                .subscribe((data: { accept: boolean; doNotShowMessage: boolean }) => {
                    this.store.dispatch(setSearchInfoDialogPreferences({ doNotShowAgain: !data?.doNotShowMessage }));
                });
        }
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
