<ng-container *ngrxLet="loadingSubtitleTranscriptTab$; let loading">
    <ng-container *ngIf="tableRows && tableRows.length > 0 && !loading">
        <ng-container *ngrxLet="currentVtc$; let currentVtc">
            <p-table
                tableStyleClass="subtitle-transcript-table"
                scrollHeight="var(--tab-scroll-height)"
                [scrollable]="true"
                responsiveLayout="scroll"
                [value]="tableRows"
                selectionMode="single"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let h of tableHeader" class="{{ h.scssClass }}" [attr.aria-label]="h.value"><span class="h-offscreen">Spaltentitel</span>{{ h.value }}</th>
                        <th class="action-header" *ngIf="!readonly" pFrozenColumn alignFrozen="right" aria-label="Tabellen Aktionen">
                            <span class="h-offscreen">Spaltentitel Tabellen Aktionen</span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-sequence let-i="rowIndex">
                    <tr
                        [appHighlightRow]="i"
                        (highlightedRowChanged)="highlightedRowIndex = $event"
                        (click)="navigateToSequence(sequence)"
                        (mouseenter)="setHoveredTimeFrame(sequence)"
                        (mouseleave)="clearHoveredTimeFrame()"
                        class="table-row subtitle-transcript-table-row"
                        [ngClass]="{
                            'active-sequence-transcript-subtitle':
                                subtitleTranscriptEntryIndex === i && subtitleTranscriptEntryIndex !== -1
                        }"
                        data-cy="subtitle-transcript-table-row"
                    >
                        <td class="subtitle-transcript-table-shot media-column" data-cy="subtitle-transcript-table-shot">
                            <img
                                [src]="
                                    ($any(sequence.keyframeBlob$ | async) | blobToObjectURL) ??
                                    'assets/images/empty-keyframe.svg'
                                "
                                alt="Untertitel {{ i + 1 }}"
                            />
                            <div class="table-entry-number" *ngIf="highlightedRowIndex === i">{{ i + 1 }}</div>
                            <div class="h-offscreen">
                                Laufzeit {{ getAccessibilityDurationString(sequence.duration) }}
                            </div>
                            <div class="duration" aria-hidden="true">
                                {{ sequence.duration ? (sequence.duration | durationFormatPipe) : "-" }}
                            </div>
                        </td>
                        <td class="subtitle-transcript-table-value">
                            <ng-container *ngFor="let line of sequence.lines">
                                <button
                                    (click)="jumpToTimeCode($event, line)"
                                    class="subtitle-transcript-line"
                                    (focus)="showActions($event, i)"
                                    [ngClass]="{
                                        'active-subtitle-transcript-line': line.text === activeSubtitleTranscriptLine
                                    }"
                                    [attr.aria-label]="line.text"
                                    ><app-highlight [text]="line.text" [keywords]="keywords"></app-highlight>
                                </button>
                            </ng-container>
                        </td>
                        <td class="action-icon-td" *ngIf="!readonly" (click)="showActions($event, i)" pFrozenColumn alignFrozen="right">
                            <button
                                type="button"
                                class="p-table-icon-button"
                                *ngIf="highlightedRowIndex !== i"
                                (click)="showActions($event, i)"
                            >
                                <img src="assets/icons/ellipsis.svg" alt="Optionen" />
                            </button>
                            <div class="p-table-action-button-container" *ngIf="highlightedRowIndex === i">
                                <ng-container *ngIf="!isAppInWebViewContainer || (canUseShoppingCart$ | async)">
                                    <button
                                        title="Mit Vermerk in den Warenkorb"
                                        pRipple
                                        type="button"
                                        class="p-table-action-button"
                                        id="{{ 'order-with-remark-' + i }}"
                                        (click)="orderWithRemark($event, sequence, 'order-with-remark-' + i)"
                                    >
                                    <img src="assets/icons/order-with-remark.svg" alt="" />
                                    </button>
                                    <button
                                        title="In den Warenkorb"
                                        pRipple
                                        type="button"
                                        class="p-table-action-button"
                                        id="{{ 'add-to-cart-' + i }}"
                                        (click)="addToShoppingCart($event, sequence, 'add-to-cart-' + i)"
                                    >
                                    <img src="assets/icons/shopping-cart-white.svg" alt="" />
                                    </button>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="tableRows && tableRows.length === 0 && !loading">
        <div class="no-details-content">
            <div>
                <img src="assets/images/empty-state.svg" alt="" />
            </div>
            <div class="no-details-text-title">
                <div><img class="no-details-warning-icon" src="/assets/icons/warning-icon.svg" alt="" /></div>
                <div>Keine Untertitel/Transkript</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loading-container">
            <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </ng-container>
</ng-container>
