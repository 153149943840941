import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddShoppingCartEntryRequestDto, AudioTrack } from '@faro/order-angular-client';
import { resetAllFilters, resetSearch, triggerResetFacetSearch } from 'src/app/search/search-state/search-options.actions';
import { dispatchEmptySearch } from 'src/app/search/search-state/search-result.actions';
import { Router } from '@angular/router';

@Injectable()
export class HostConnectorService {
    private readonly _webView: any = undefined;

    constructor(private readonly store: Store,
        private readonly router: Router
    ) {
        // are we running in chromium webview container host?
        const chrome = (window as any).chrome;
        if (chrome) {
            this._webView = chrome.webview;
        }
        if (this._webView) {
            this._webView.addEventListener('message', (e: MessageEvent) => this.onMessage(e));
        }
    }

    public isAppInWebViewContainer(): boolean {
        return !!this._webView;
    }

    public get webView() {
        return this._webView;
    }

    private onMessage(event: MessageEvent): void {
        if (event.data === "goHome") {
            this.goHome();
        }
    }

    async goHome() {
        this.store.dispatch(triggerResetFacetSearch());
        this.store.dispatch(resetSearch());
        this.store.dispatch(resetAllFilters());
        this.store.dispatch(dispatchEmptySearch());
        return this.router.navigate(['/']);
    }

    private emitMessage(message: HostMessage): void {
        if (this._webView) {
            this._webView.postMessage(message);
        }
    }

    private emitBasicEntityMessage(entityInfo: EntityInfo, action: Action) {
        let id = entityInfo.sequenceId ?? entityInfo.itemId;
        let entityType = entityInfo.sequenceId ? EntityType.Shot : EntityType.Item;

        if (id) {
            const message: EntityMessage = {
                messageType: 'EntityMessage',
                action: action,
                type: entityType,
                id,
            };
            this.emitMessage(message);
        }
    }

    emitDocSetMessage(entityInfo: EntityInfo) {
        this.emitBasicEntityMessage(entityInfo, Action.DocSet);
    }

    emitDetailMessage(entityInfo: EntityInfo) {
        this.emitBasicEntityMessage(entityInfo, Action.Detail);
    }

    emitPlayerMessage(entityInfo: EntityInfo) {
        this.emitBasicEntityMessage(entityInfo, Action.Player);
    }

    emitCatalogingEditorMessage(entityInfo: EntityInfo) {
        this.emitBasicEntityMessage(entityInfo, Action.Cataloging);
    }

    emitAddShoppingCartEntryMessage(item: AddShoppingCartEntryRequestDto): void {
        let id = item.sequenceId ?? (item.itemId === null ? undefined : item.itemId);
        let entityType = item.sequenceId ? EntityType.Shot : EntityType.Item;
        if (id) {
            const message: EntityMessage = {
                messageType: 'EntityMessage',
                action: Action.ShoppingCart,
                type: entityType,
                id,
                remark: item.remark ?? undefined,
                audioTrack: item.selectedAudioTrack,
                mediaCutId: item.mediaCutId ?? undefined,
                vtcIn: item.vtcIn ?? undefined,
                vtcOut: item.vtcOut ?? undefined
            };
            this.emitMessage(message);
        }
    }

    emitAction(action: Action): void {
        const message: HostMessage = {
            messageType: 'HostMessage',
            action: action,
        };
        this.emitMessage(message);
    }
}

interface HostMessage {
    messageType: string;
    action: Action;
}

interface EntityMessage extends HostMessage {
    type: EntityType;
    id: string;
    remark?: string;
    audioTrack?: AudioTrack;
    mediaCutId?: string;
    vtcIn?: string;
    vtcOut?: string;
}

export enum Action {
    Detail = 'Detail',
    DocSet = 'DocSet',
    ShoppingCart = 'ShoppingCart',
    AuthToken = 'AuthToken',
    ActionLog = 'ActionLog',
    Player = "Player",
    Cataloging = "Cataloging",
}

export interface EntityInfo {
    programId: string,
    itemId?: string,
    sequenceId?: string
}

export enum EntityType {
    Shot = 'Shot',
    Item = 'Item',
}