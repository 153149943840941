<div class="dialog-content" role="dialog" aria-labelledby="dialogTitle" aria-modal="true">
    <div class="dialog-header">
        <h2 class="title" id="dialogTitle">{{ config.data.title }}</h2>
        <div>
            <button
                data-no-search
                pRipple
                class="close-icon"
                autofocus
                aria-label="Dialog schliessen"
                (click)="closeDialog()"
            >
                <img src="/assets/icons/close-icon.svg" alt="Dialog schliessen" />
            </button>
        </div>
    </div>
    <div class="profile-dialog-main">
        <div *ngrxLet="isLoadingCategories$; let isLoadingCategories">
            <faro-dialog-panel header="Ablagestruktur"></faro-dialog-panel>
            <div class="dialog-options">
                <ng-container *ngFor="let filter of availableFilters">
                    <div class="dialog-options-entries">
                        <div class="checkbox-label">{{ filter.label }}</div>
                        <div>
                            <p-inputSwitch
                                (onChange)="filterTree()"
                                [(ngModel)]="filter.active"
                                [ariaLabel]="filter.label"
                            ></p-inputSwitch>
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="isLoadingCategories">
                <faro-tree-select class="tree-select" [isLoading]="isLoadingCategories"></faro-tree-select>
            </ng-container>
            <ng-container *ngIf="!isLoadingCategories">
                <ng-container *ngrxLet="storageCategories$; let storageCategories">
                    <faro-tree-select
                        class="tree-select"
                        [excludedNodes]="excludedStorageCategories"
                        [treeEntries]="storageCategories ?? []"
                        [isLoading]="isLoadingCategories"
                        (excludedNodesChanged)="onExcludedSelectionChange($event)"
                    >
                    </faro-tree-select>
                </ng-container>
            </ng-container>
        </div>
        <div>
            <faro-dialog-panel header="Medium"></faro-dialog-panel>
            <div class="dialog-options">
                <ng-container *ngFor="let mediaType of availableMediaTypes">
                    <div class="dialog-options-entries">
                        <div class="checkbox-label">{{ mediaType.label }}</div>
                        <div>
                            <p-inputSwitch
                                [(ngModel)]="mediaType.active"
                                ariaLabel="In {{ mediaType.label }} suchen"
                            ></p-inputSwitch>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="divider"></div>
            <faro-dialog-panel header="Art der Suche"></faro-dialog-panel>
            <div class="dialog-options">
                <div class="dialog-options-entries">
                    <div class="checkbox-label">In Unterlagen, Protokolle, Dopesheets</div>
                    <div>
                        <p-inputSwitch
                            name="searchInDocText"
                            [(ngModel)]="searchInDocumentTexts"
                            ariaLabel="Nicht in Unterlagen, Protokolle, Dopesheets suchen"
                        ></p-inputSwitch>
                    </div>
                </div>
                <div class="dialog-options-entries">
                    <div class="checkbox-label">Sportfilter einblenden</div>
                    <div>
                        <p-inputSwitch
                            name="includeSportsFilters"
                            [(ngModel)]="includeSportsFilters"
                            ariaLabel="Sportfilter einblenden"
                        ></p-inputSwitch>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-footer footer-space-between">
        <div>
        <button
            data-no-search
            class="dialog-secondary-button align-start"
            type="button"
            pButton
            icon="pi pi-replay"
            (click)="resetSearchSettings()"
            *ngIf="(areProfileSettingsDefault$ | async) === false"
            [disabled]="(areProfileSettingsDefault$ | async)"
            label="Auf Standard zurücksetzen"
        ></button>
        </div>
        <div class="profile-action-buttons">
        <button
            #saveSettingsButton
            data-no-search
            class="search-button"
            type="button"
            pButton
            icon="pi pi-check"
            (click)="save()"
            label="Speichern"
        ></button>
        <button
            data-no-search
            class="dialog-secondary-button"
            type="button"
            pButton
            icon="pi pi-times"
            (click)="closeDialog()"
            label="Abbrechen"
        ></button>
        </div>
    </div>
</div>
