import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';

export const userFeatureKey = 'user';

export const selectUser = createFeatureSelector<UserState>(userFeatureKey);

export const selectUserId = createSelector(selectUser, (state: UserState) => state.userInfo?.id);

export const selectUserEmail = createSelector(selectUser, (state: UserState) => state.userInfo?.email);

export const selectUserName = createSelector(selectUser, (state: UserState) => state.userInfo?.name);

export const selectCanUseDocSet = createSelector(selectUser, (state: UserState) => !!state.userInfo?.userCanUseDocSet);

export const selectCanUseShoppingCart = createSelector(selectUser, (state: UserState) => !!state.userInfo?.userCanUseShoppingCart);

export const selectCanViewCataloging = createSelector(selectUser, (state: UserState) => !!state.userInfo?.userCanViewCataloging);
