<div class="container">
    <div class="checkBox" *ngIf="showSelectAll" aria-label="Baumstruktur alle aus-/abwählen">
        <p-triStateCheckbox
            role="checkbox"
            checkboxFalseIcon="pi pi-minus"
            (onChange)="onCheckBoxChanged($event)"
            [(ngModel)]="summarySelectedState"
            label="Alle"
            [attr.aria-label]="'Alle'"
            [attr.aria-checked]="summarySelectedState"
        ></p-triStateCheckbox>
    </div>
    <ng-container *ngIf="!isLoading">
        <div id="treeLabel" class="h-offscreen">Archivablage-Baum</div>
        <p-tree
            [value]="filteredList"
            selectionMode="checkbox"
            ariaLabelledBy="treeLabel"
            [(selection)]="selectedNodes"
            (onNodeUnselect)="anyNodeChange()"
            (onNodeSelect)="anyNodeChange()"
            [styleClass]="'my-tree'"
            emptyMessage="Keine Einträge gefunden"
            scrollHeight="{{ 'var(--tree-scroll-height)' }}"
        ></p-tree>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <div class="loading-tree">
            <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </ng-container>
</div>
