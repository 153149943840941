<ng-container *ngrxLet="loadingSequenceTab$; let loading">
    <ng-container *ngIf="tableRows && tableRows.length > 0 && !loading">
        <ng-container *ngrxLet="currentVtc$; let currentVtc">
            <p-table
                tableStyleClass="detail-table"
                scrollHeight="var(--tab-scroll-height)"
                [scrollable]="true"
                responsiveLayout="scroll"
                [value]="tableRows"
                selectionMode="single"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let h of tableHeader" class="{{ h.scssClass }}" [attr.aria-label]="h.value ? h.value : h.offscreenHeader"><span class="h-offscreen" *ngIf="h.value">Spaltentitel</span>{{ h.value }}<span *ngIf="!h.value" class="h-offscreen">Spaltentitel {{h.offscreenHeader}}</span></th>
                        <th class="action-header" *ngIf="!readonly" pFrozenColumn alignFrozen="right" aria-label="Tabellen Aktionen">
                            <span class="h-offscreen">Spaltentitel Tabellen Aktionen</span>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-sequence let-i="rowIndex">
                    <ng-template #constraintsTooltipTemplate>
                        <app-restriction-tooltip [restrictions]="sequence.rightsRestrictions"></app-restriction-tooltip>
                    </ng-template>

                    <tr
                        [appHighlightRow]="i"
                        (highlightedRowChanged)="highlightedRowIndex = $event"
                        (click)="navigateToSequence(sequence)"
                        (mouseenter)="setHoveredTimeFrame(sequence)"
                        (mouseleave)="clearHoveredTimeFrame()"
                        class="table-row details-table-row"
                        [ngClass]="{ 'active-sequence-transcript-subtitle': sequence.sequenceId === activeSequenceId }"
                        data-cy="details-table-row"
                    >
                        <td class="details-table-shot media-column" data-cy="details-table-shot">
                            <img
                                [src]="
                                    ($any(sequence.keyframeBlob$ | async) | blobToObjectURL) ??
                                    'assets/images/empty-keyframe.svg'
                                "
                                alt="Sequenz {{ i + 1 }}"
                            />
                            <div class="table-entry-number" *ngIf="highlightedRowIndex === i">{{ i + 1 }}</div>
                            <div class="h-offscreen">
                                Laufzeit {{ getAccessibilityDurationString(sequence.duration) }}
                            </div>
                            <div class="duration" aria-hidden="true">
                                {{ sequence.duration ? (sequence.duration | durationFormatPipe) : "-" }}
                            </div>
                        </td>
                        <td class="details-table-place">
                            <app-highlight [text]='sequence.country' [keywords]='keywords'></app-highlight>
                            <br />
                            <app-highlight [text]='sequence.location' [keywords]='keywords'></app-highlight>
                        </td>
                        <td class="details-table-description">
                            <app-highlight [text]='sequence.description' [keywords]='keywords'></app-highlight>
                        </td>
                        <td class="details-table-value">
                            <ng-container *ngIf="sequence.rating">
                                <img src="assets/icons/diamond.svg" alt="Wertigkeit" pTooltip="{{ sequence.rating }}" />
                            </ng-container>
                        </td>
                        <td class="details-table-usage">
                            <span *ngIf="showConstraintTooltipLink(sequence.rightsRestrictions)" 
                                [pTooltip]="constraintsTooltipTemplate"
                                tooltipStyleClass="tooltip"
                                [escape]="false"
                                tooltipPosition="left"
                                >Rechte beachten!</span
                            >
                        </td>
                        <td class="action-icon-td" *ngIf="!readonly" (click)="showActions($event, i)" pFrozenColumn alignFrozen="right">
                            <button
                                type="button"
                                class="p-table-icon-button"
                                *ngIf="highlightedRowIndex !== i"
                                (click)="showActions($event, i)"
                            >
                                <img src="assets/icons/ellipsis.svg" alt="Optionen" />
                            </button>
                            <div class="p-table-action-button-container" *ngIf="highlightedRowIndex === i">
                                <ng-container *ngIf="!isAppInWebViewContainer || canUseShoppingCart">
                                <button
                                    title="Mit Vermerk in den Warenkorb"
                                    pRipple
                                    type="button"
                                    class="p-table-action-button"
                                    id="{{ 'order-with-remark-' + i }}"
                                    (click)="orderWithRemark($event, sequence, 'order-with-remark-' + i)"
                                >
                                    <img src="assets/icons/order-with-remark.svg" alt="" />
                                </button>
                                <button
                                    title="In den Warenkorb"
                                    pRipple
                                    type="button"
                                    class="p-table-action-button"
                                    id="{{ 'add-to-cart-' + i }}"
                                    (click)="addToShoppingCart($event, sequence, 'add-to-cart-' + i)"
                                >
                                    <img src="assets/icons/shopping-cart-white.svg" alt="In den Warenkorb" />
                                </button>
                                </ng-container>
                                <ng-container *ngIf="isAppInWebViewContainer && canUseDocSet">
                                    <div class="break"></div>
                                    <button
                                        data-no-search
                                        title="In das Docset"
                                        pRipple
                                        type="button"
                                        class="p-table-action-button"
                                        id="{{ 'add-to-docset-' + i }}"
                                        (click)="addToDocSet($event, sequence, 'add-to-docset-' + i)"
                                    >
                                        <img src="assets/icons/docset.svg" alt="" />
                                    </button>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="tableRows && tableRows.length === 0 && !loading">
        <div class="no-details-content">
            <div>
                <img src="assets/images/empty-state.svg" alt="" />
            </div>
            <div class="no-details-text-title">
                <div><img class="no-details-warning-icon" src="/assets/icons/warning-icon.svg" alt="" /></div>
                <div>Keine Sequenzen</div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loading">
        <div class="loading-container">
            <p-progressSpinner strokeWidth="2" animationDuration="5s" styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </ng-container>
</ng-container>
