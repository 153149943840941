import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, of, scan, Subject, map, takeUntil, delay, catchError } from 'rxjs';
import { Hit } from '@faro/searchapi-angular-client';
import {
    selectHitlist,
    selectIsLoading,
    selectShowHitlist,
    selectShowNoHits,
    selectIsBeforeFirstSearch,
    selectTotalHitCount,
    selectIsLoadingNextPage,
    selectLastResponseSearchType,
} from '../../search-state/search-result.selectors';
import { HitMapperService } from './hit-mapper.service';
import { cancelSearch } from '../../search-state/search-result.actions';
import { selectSearchProfileDefault } from '../../search-state/search-profile.selectors';
import { TableHitComponentModel } from './table-hitlist/table-hit-component.model';
import { KeyframesService } from '@faro/metadata-angular-client';
import { selectSearchFieldSelection } from '../../search-state/search-options.selectors';
import {
    SEARCH_FILTERS,
    SearchFieldSelectionEnum,
    SearchFieldSelectionOption,
} from '../../search-state/search-options.state';
import { setSearchFieldSelection } from '../../search-state/search-options.actions';

@Component({
    selector: 'app-hitlist',
    templateUrl: './hitlist.component.html',
    styleUrls: ['./hitlist.component.scss'],
})
export class HitlistComponent implements OnInit, OnDestroy {
    totalHitCount$: Observable<number | undefined>;
    tableHitlist$: Observable<TableHitComponentModel[]>;
    isLoading$: Observable<boolean>;
    isLoadingNextPage: boolean = false;
    areProfileFiltersSet$: Observable<boolean>;
    showNoSearch$: Observable<boolean>;
    showNoHits$: Observable<boolean>;
    hideHitlist$: Observable<boolean>;
    private selectedSearchOption: SearchFieldSelectionOption = { value: SearchFieldSelectionEnum.TOPIC };

    private _destroyed$ = new Subject<void>();

    constructor(
        public readonly store: Store,
        private readonly hitMapper: HitMapperService,
        private readonly keyframeService: KeyframesService
    ) {
        this.store
            .select(selectSearchFieldSelection)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => (this.selectedSearchOption = data));
        this.tableHitlist$ = this.store.select(selectHitlist).pipe(
            distinctUntilChanged(),
            scan((currentModels: TableHitComponentModel[], hitlist: Hit[]) => {
                /** very important to reuse existing models, otherwise keyframe images are re-fetched on lazy loading...
                 * in case a new search is executed the existing models will not be reused. Important for highlighting
                 * */
                const lazyLoaded = hitlist.length > 50;
                if (lazyLoaded) {
                    return hitlist.map((hit, ix) => {
                        const currentModel = currentModels[ix];
                        return currentModel?.hitId === hit.id
                            ? currentModel
                            : this.createHitComponentModel(hit, this.selectedSearchOption);
                    });
                } else {
                    return hitlist.map(hit => {
                        return this.createHitComponentModel(hit, this.selectedSearchOption);
                    });
                }
            }, [])
        );

        this.totalHitCount$ = this.store.select(selectTotalHitCount);
        this.isLoading$ = this.store.select(selectIsLoading);
        this.showNoSearch$ = this.store.select(selectIsBeforeFirstSearch);
        this.showNoHits$ = this.store.select(selectShowNoHits);
        this.hideHitlist$ = this.store.select(selectShowHitlist).pipe(map(show => !show));
        this.areProfileFiltersSet$ = this.store.select(selectSearchProfileDefault);

        this.store
            .select(selectLastResponseSearchType)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                if (data) {
                    if (data !== this.selectedSearchOption.value) {
                        const searchFilter = SEARCH_FILTERS.find(v => v.value === data);
                        if (searchFilter) {
                            this.store.dispatch(setSearchFieldSelection({ searchFieldSelection: searchFilter }));
                        }
                    }
                }
            });
    }

    private createHitComponentModel(
        hit: Hit,
        selectedSearchOption: SearchFieldSelectionOption
    ): TableHitComponentModel {
        const model = this.hitMapper.mapHitToTableHitComponentModel(hit, selectedSearchOption);
        const delayInMs = (hit as any).indexOnPage || 0;
        model.keyframeBlob$ =
            model.keyframeId! >= 0
                ? this.keyframeService.keyframesGet(model.keyframeId!).pipe(
                      delay(delayInMs),
                      catchError(_ => of(null))
                  )
                : of(null);

        return model;
    }

    ngOnInit() {
        this.store
            .select(selectIsLoadingNextPage)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this.isLoadingNextPage = data;
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    onCancelSearch() {
        this.store.dispatch(cancelSearch());
    }
}
