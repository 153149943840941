import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, Observable, Subject, take, takeUntil } from 'rxjs';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import { selectActiveShoppingCart, selectSelectedShoppingCart } from '../shopping-cart-state/shopping-cart.selectors';
import { PaginationValues } from '../../ui-library/hitlist-paginator/paginator.interface';
import { ShoppingCartEntryDto } from '@faro/order-angular-client/model/shoppingCartEntryDto';
import {
    getDetailItemNavInformation,
    getDetailMediaCuts,
    getDetailProgramNavInformation,
    setDetailAvComponent,
    setDetailShoppingCartVtcInVtcOut,
    setMediaSelection,
} from '../../details/details-state/details.actions';
import { getActiveShoppingCart, getSelectedShoppingCart } from '../shopping-cart-state/shopping-cart.actions';
import { parseTimeSpan } from '../../shared/timespan';
import { TimeService } from '../../details/shared/services/time.service';
import { selectDetailPlayerMediaCut } from '../../details/details-state/details.selectors';
import { PrintReportService } from '../shared/print-report.service';
import { isSubRange } from 'src/app/shared/time-range.helpers';

@Component({
    selector: 'app-shopping-cart-detail',
    templateUrl: './shopping-cart-detail.component.html',
    styleUrls: ['./shopping-cart-detail.component.scss'],
})
export class ShoppingCartDetailComponent implements OnInit, OnDestroy {
    cartInfo$: Observable<ShoppingCartDto>;
    entryId: string = '';
    activeMetaData: any;
    activeIndex: number = -1;
    isActiveCartView: boolean = false;
    entryTitle: string = '';
    private _destroyed$ = new Subject<void>();

    constructor(
        private router: Router,
        private store: Store,
        private activatedRoute: ActivatedRoute,
        private readonly timeService: TimeService,
        private readonly printService: PrintReportService
    ) {
        this.isActiveCartView = this.router.url.includes('active');
        if (this.isActiveCartView) {
            this.cartInfo$ = this.store.select(selectActiveShoppingCart).pipe(
                filter(v => !!v),
                map(v => v as ShoppingCartDto)
            );
            this.store
                .select(selectActiveShoppingCart)
                .pipe(take(1))
                .subscribe(data => {
                    if (!data) {
                        this.store.dispatch(getActiveShoppingCart());
                    }
                });
        } else {
            this.cartInfo$ = this.store.select(selectSelectedShoppingCart).pipe(
                filter(v => !!v),
                map(v => v as ShoppingCartDto)
            );
            this.store
                .select(selectSelectedShoppingCart)
                .pipe(take(1))
                .subscribe(data => {
                    if (!data) {
                        this.activatedRoute.params.pipe(take(1)).subscribe(data => {
                            const cartId = data['cartId'];
                            this.store.dispatch(getSelectedShoppingCart({ cartId }));
                        });
                    }
                });
        }
    }

    ngOnInit() {
        this.activatedRoute.params.pipe(takeUntil(this._destroyed$)).subscribe(data => {
            this.entryId = data['cartEntryId'];
            this.cartInfo$.pipe(takeUntil(this._destroyed$)).subscribe(cart => {
                if (cart && cart.entries) {
                    this.activeIndex = cart.entries.findIndex(v => v.id === this.entryId) ?? -1;
                    const cartEntry = cart.entries[this.activeIndex];
                    const metaData = (cart.entriesMetadata as any).filter((md: any) => md.entryId === this.entryId)[0];  
                    const itemId = this.getItemId(cartEntry, metaData); 
                    this.entryTitle = metaData.itemTitle ?? metaData.programTitle;
                    this.activeMetaData = metaData;
                    this.store.dispatch(
                        setDetailAvComponent({
                            program: cartEntry.programId,
                            item: itemId ?? undefined,
                            contentType: itemId ? 'item' : 'program',
                        })
                    );
                    if (cartEntry.programId && !itemId) {
                        this.store.dispatch(getDetailProgramNavInformation({ programId: cartEntry.programId }));
                        this.store.dispatch(getDetailMediaCuts({ programId: cartEntry.programId }));
                    } else if (cartEntry.programId && itemId) {
                        this.store.dispatch(
                            getDetailItemNavInformation({ programId: cartEntry.programId, itemId: itemId })
                        );
                        this.store.dispatch(
                            getDetailMediaCuts({ programId: cartEntry.programId, itemId: itemId })
                        );
                    }
                    const playable = playableMediaCut(cartEntry.selectedMediaCuts, cart.mediaCutsMetadata);
                    const mediaCut = playable.length > 0 ? playable[0] : undefined;
                    const audioTrack =
                        cartEntry.selectedAudioTrack === 'None' ? undefined : cartEntry.selectedAudioTrack;
                    this.store.dispatch(setMediaSelection({ mediaCut, audioTrack }));
                    if (cartEntry.entryType === 'TCSelection') {
                        this.store.dispatch(
                            setDetailShoppingCartVtcInVtcOut({
                                vtcIn: parseTimeSpan(cartEntry.vtcIn!),
                                vtcOut: parseTimeSpan(cartEntry.vtcOut!),
                            })
                        );
                        this.store
                            .select(selectDetailPlayerMediaCut)
                            .pipe(filter(data => !!data))
                            .pipe(take(1))
                            .subscribe(() => {
                                this.timeService.jumpToVtc(parseTimeSpan(cartEntry.vtcIn!));
                            });
                    }
                    if (cartEntry.entryType === 'Sequence') {
                        this.store.dispatch(
                            setDetailShoppingCartVtcInVtcOut({
                                vtcIn: parseTimeSpan(metaData.sequenceVtcIn),
                                vtcOut: parseTimeSpan(metaData.sequenceVtcOut),
                            })
                        );
                        this.store
                            .select(selectDetailPlayerMediaCut)
                            .pipe(filter(data => !!data))
                            .pipe(take(1))
                            .subscribe(() => {
                                this.timeService.jumpToVtc(parseTimeSpan(metaData.sequenceVtcIn));
                            });
                    }
                }
            });
        });
    }

    private getItemId(cartEntry: ShoppingCartEntryDto, entryMetaData: any) {
        // special case for TC-Selection which is completely contained in an item, but there is no itemId on the cart entry
        // this allows mediacuts of type Item to be played in that case (cf. SRFFARO-1108)
        const isSpecialCase = cartEntry.entryType === 'TCSelection' 
                    && !cartEntry.itemId && entryMetaData.itemId
                    && isSubRange(parseTimeSpan(cartEntry.vtcIn!), 
                                parseTimeSpan(cartEntry.vtcOut!), 
                                parseTimeSpan(entryMetaData.itemVtcIn ?? '0'), 
                                parseTimeSpan(entryMetaData.itemVtcOut ?? '0'));

        return !isSpecialCase ? cartEntry.itemId : entryMetaData.itemId;
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    getSelectedIndex(id: string, entries: ShoppingCartEntryDto[] | undefined): number {
        return entries?.findIndex(v => v.id === id) ?? -1;
    }

    onPaginationRequested(
        type: PaginationValues,
        shoppingEntryList: ShoppingCartEntryDto[] | undefined,
        index: number,
        cartId: string
    ): void {
        if (!shoppingEntryList || shoppingEntryList.length === 0) return;

        let entryId: string;

        switch (type) {
            case 'first':
                entryId = shoppingEntryList[0].id;
                this.navigateToPage(cartId, entryId);
                break;
            case 'last':
                entryId = shoppingEntryList[shoppingEntryList.length - 1].id;
                this.navigateToPage(cartId, entryId);
                break;
            case 'next':
                entryId = shoppingEntryList[index + 1].id;
                this.navigateToPage(cartId, entryId);
                break;
            case 'prev':
                entryId = shoppingEntryList[index - 1].id;
                this.navigateToPage(cartId, entryId);
                break;
        }
    }

    navigateToPage(cartId: string, entryId: string) {
        if (this.isActiveCartView) {
            this.router.navigateByUrl(`shopping-cart/active/${entryId}`).then();
        } else {
            this.router.navigateByUrl(`shopping-cart/preview/${cartId}/${entryId}`).then();
        }
    }

    printShoppingCartReport() {
        this.printService.printItemReport(this.activeMetaData.programId, this.activeMetaData.itemId, this.entryTitle);
    }
}

function playableMediaCut(selectedMediaCuts: string[] | undefined, mediaCuts: any[]) {
    let playableMediaCuts: any[] = [];
    if (selectedMediaCuts) {
        playableMediaCuts = selectedMediaCuts.filter(id => {
            return mediaCuts.filter(
                v =>
                    v.id === id &&
                    (v.mediaCutType === 'Standard' || v.mediaCutType === 'Item') &&
                    (v.mediaType === 'Video' || v.mediaType === 'Audio')
            );
        });
    }
    return playableMediaCuts;
}
