import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getUserInformation, setUserInformation } from './user.actions';
import { Injectable } from '@angular/core';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { UserService } from '../auth/user.service';
import { BasicUserInformation, UserInformation } from './user.state';
import { UserInfoRecord, UserInfoService } from '@faro/rights-angular-client';

@Injectable()
export class UserEffects {
    constructor(private actions$: Actions, private userService: UserService, private userInfoService: UserInfoService) { }

    getUserInformation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUserInformation),
            switchMap(() => {
                return this.userService.userInformation$.pipe(
                    switchMap((basicUserInfo: BasicUserInformation) => this.userInfoService.userInfoGetUserInfo(basicUserInfo.id!)
                        .pipe(
                            map((userInfoRecord: UserInfoRecord) => setUserInformation({ userInfo: this.makeUserInfo(userInfoRecord) })),
                            catchError(error => of(setUserInformation({ userInfo: this.makeFallbackUserInfo(basicUserInfo) })))
                        )
                    )
                )
            })
        )
    })

    private makeUserInfo(userRecord: UserInfoRecord): UserInformation {
        return {
            id: userRecord?.id!,
            email: userRecord?.email!,
            name: `${userRecord?.firstname ?? ''} ${userRecord?.lastname ?? ''}`.trim(),
            userCanUseDocSet: !!userRecord?.systemgroup?.userCanUseDocSet,
            userCanUseShoppingCart: !!userRecord?.systemgroup?.userCanUseShoppingCart,
            userCanViewCataloging: !!userRecord?.systemgroup?.userCanViewCataloging,
        }
    }

    private makeFallbackUserInfo(basicUserInfo: BasicUserInformation): UserInformation {
        return {
            id: basicUserInfo?.id!,
            email: basicUserInfo?.eMail!,
            name: basicUserInfo?.name!,
            userCanUseDocSet: false,
            userCanUseShoppingCart: false,
            userCanViewCataloging: false
        }
    }
}
