<p-selectButton
    data-no-search
    class="search-select"
    [options]="selectOptions"
    [ngModel]="selectedValue"
    [multiple]="false"
    [allowEmpty]="false"
    optionLabel="label"
    (onChange)="onSelectionChange($event.value)"
><ng-template let-item pTemplate><div class="select-button-text" [title]="item.tooltip">{{item.label}}</div></ng-template></p-selectButton>
