import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
    BehaviorSubject,
    combineLatest,
    EMPTY,
    Observable,
    of,
    sampleTime,
    Subject,
    switchMap,
    take,
    takeUntil,
    catchError,
    filter,
    map,
    mergeMap,
} from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Duration, fromMilliseconds, fromSeconds } from '../../shared/duration';
import { HlsBackendFlowPlayer } from './hls-backend-flow-player';
import { HlsService } from '../backend-api/hls.service';
import { formatTimeCode } from '../shared/time-code-format.model';
import { TimeProvider } from '../time-provider.model';
import { MediaInformation } from './media-information.interface';
import { RestrictionDto } from '../shared/restriction.model';
import { MediaCutInfo } from '../shared/media-cut-info';
import { Menu } from 'primeng/menu';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
    selector: 'app-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss'],
    animations: [
        trigger('displayHideVolumeBar', [
            state(
                'displayVolumeBar',
                style({
                    width: '50px',
                    margin: '0',
                })
            ),
            state(
                'hideVolumeBar',
                style({
                    width: 0,
                    margin: '0',
                })
            ),
            transition('hideVolumeBar => displayVolumeBar', animate('.5s')),
            transition('displayVolumeBar => hideVolumeBar', animate('.5s')),
        ]),
    ],
})
export class PlayerComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy, TimeProvider {
    @ViewChild('controls') controls: ElementRef | undefined;
    @ViewChild('playerController') playerController: ElementRef | undefined;
    @ViewChild('playBackRateMenu') playBackRateMenu: Menu | undefined;

    private static _seq: number = 0;

    @Input()
    media: MediaInformation | null = null;

    @Input()
    restrictions: RestrictionDto[] = [];

    @Input()
    markerVtcIn: Duration | undefined;

    @Input()
    markerVtcOut: Duration | undefined;

    @Input()
    previousPlayerState: boolean | null = false;

    @Input()
    controlVisibility = false;

    @Output()
    audioTrackRequested = new EventEmitter<string>();

    @Output()
    mediaCutRequested = new EventEmitter<string>();

    @Output()
    mediaCutLoaded = new EventEmitter<MediaCutInfo | null>();

    @Output()
    playingStarted = new EventEmitter<unknown>();

    @Output()
    playState = new EventEmitter<boolean>();

    get isAudioProgram(): boolean {
        return this.media?.isAudioProgram || false;
    }

    readonly flowPlayerId: string = 'flow_player_' + PlayerComponent._seq++;

    @ViewChild('flowPlayer') flowPlayerElement: ElementRef | undefined;

    playerLoaded = false;

    relativePosition = 0;

    audioOptions: MenuItem[] = [];
    playbackRateOptions: MenuItem[] = [];
    materialOptions: MenuItem[] = [];
    isDefaultAudioOption = true;

    hideControlButtons = false;
    hideRemainingControlButtons = false;
    hideMainControl = false;
    mouseInsideControls = false;
    private mainControlTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {}, 0);

    playing$ = of(false);

    private _currentVtc$ = new BehaviorSubject<Duration | null>(null);

    currentVtc$ = this._currentVtc$.asObservable();

    currentTimeLabel = '';

    seekOverlayUrl$: Observable<SafeUrl | null> = EMPTY;

    private _destroyed$: Subject<void> = new Subject<void>();

    private _player: HlsBackendFlowPlayer | null = null;

    private _mediaCut$ = new BehaviorSubject<MediaCutInfo | null>(null);

    private volumeBarTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {}, 0);
    mediaCut$ = this._mediaCut$.asObservable();
    selectedPlaybackRateLabel = '1x';
    selectedMaterialLabel = '';
    displayVolumeBar: boolean = false;
    heightOnAspectRatio43 = '100%';
    widthOnAspectRatio43 = '100%';
    aspectRatioVariable = 1.3333;

    resizeObserver = new ResizeObserver(entries => {
        if (this.aspectRatio === '4:3') {
            const videoElement = entries[0].target.getElementsByTagName('video');
            const playerElement = entries[0];
            const width = playerElement.contentRect.width;
            const height = playerElement.contentRect.height;
            if (height < width) {
                this.heightOnAspectRatio43 = `${width / this.aspectRatioVariable}px`;
                this.widthOnAspectRatio43 = 'unset';
                videoElement[0].style.height = `${width / this.aspectRatioVariable}px`;
                videoElement[0].style.width = 'unset';
            } else {
                this.heightOnAspectRatio43 = 'unset';
                this.widthOnAspectRatio43 = `${height * this.aspectRatioVariable}px`;
                videoElement[0].style.height = 'unset';
                videoElement[0].style.width = `${height * this.aspectRatioVariable}px`;
            }
        }
    });

    constructor(
        private readonly videoService: HlsService,
        private readonly sanitizer: DomSanitizer,
        private readonly cdr: ChangeDetectorRef,
        private zone: NgZone,
        private focusMonitor: FocusMonitor
    ) {}

    @HostListener('window:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const element = (event.target as HTMLElement).tagName.toLowerCase();
        const keyFromButtonElement = element ? element === 'button' : false;
        const keyFromInputElement = element ? element === 'input' : false;
        if (!keyFromButtonElement && !keyFromInputElement) {
            if (event.code === 'Space') {
                this._player?.isPlaying() ? this._player?.pause() : this._player?.play();
            }
        }
        if (!keyFromInputElement) {
            if (event.code === 'KeyA') {
                this._player?.playbackRate === 1 ? this.setPlaybackRate(2) : this.setPlaybackRate(1);
            }
        }
    }

    async ngAfterViewInit() {
        await this.initFlowPlayer(this.flowPlayerId);
        const player = this._player!;

        combineLatest([player.currentLinearTime$, this._mediaCut$.asObservable()])
            .pipe(takeUntil(this._destroyed$))
            .subscribe(([ltc, mc]) => {
                if (!mc) {
                    this._currentVtc$.next(null);
                } else {
                    const vtc = mc.timeCodeMap.ltcToVtc(ltc);

                    this.relativePosition = calcRelativePosition(vtc, mc);

                    const rtc = mc.timeCodeMap.vtcToRtc(vtc);
                    this.currentTimeLabel = formatTimeCode(rtc, mc.frameRate);

                    this._currentVtc$.next(vtc);
                    this.cdr.detectChanges();
                }
            });

        player.playing$
            .pipe(
                takeUntil(this._destroyed$),
                filter(playing => playing)
            )
            .subscribe(_ => {
                this.playingStarted.emit(true);
            });

        const seekOverlayUrl$1: Observable<SafeUrl | null> = this.currentVtc$.pipe(
            sampleTime(100),
            mergeMap(vtc => {
                if (vtc) {
                    return player.getStillImage(vtc).pipe(
                        catchError(_ => EMPTY),
                        map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)))
                    );
                } else {
                    return of(null);
                }
            })
        );

        this.seekOverlayUrl$ = combineLatest([player.seeking$, player.playing$]).pipe(
            map(([seeking, playing]) => seeking || !playing),
            switchMap(showOverlay => (showOverlay ? seekOverlayUrl$1 : of(null)))
        );

        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.controls) {
                    const resizeObserver = new ResizeObserver(entries => {
                        const controls = entries[0];
                        if (controls) {
                            const controlWidth = controls.contentRect.width;
                            this.hideControlButtons = controlWidth < 730;
                            this.hideRemainingControlButtons = controlWidth < 610;
                        }
                    });
                    resizeObserver.observe(this.controls.nativeElement);
                }
            }, 500);
        });
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.flowPlayerElement) {
                    this.resizeObserver.observe(this.flowPlayerElement.nativeElement);
                }
                if (this.playerController && !this.isAudioProgram) {
                    this.focusMonitor.monitor(this.playerController, true).subscribe(data => {
                        if (data) {
                            this.hideMainControl = false;
                            this.hideControlsWithTimeout();
                        } else {
                            this.hideControlsWithTimeout();
                        }
                    });
                }
            }, 500);
        });
    }

    displayMainControls() {
        this.zone.runOutsideAngular(() => {
            if (this.isAudioProgram) return;
            this.hideMainControl = !this.hideMainControl;
        });
    }

    mouseMoving() {
        this.zone.runOutsideAngular(() => {
            this.hideMainControl = false;
            this.hideControlsWithTimeout();
        });
    }

    mouseLeaveControls() {
        this.zone.runOutsideAngular(() => {
            this.mouseInsideControls = false;
            this.hideControlsWithTimeout();
        });
    }

    mouseEnterControls() {
        this.zone.runOutsideAngular(() => {
            this.hideMainControl = false;
            this.mouseInsideControls = true;
        });
    }

    hideControlsWithTimeout() {
        clearTimeout(this.mainControlTimeout);
        this.mainControlTimeout = setTimeout(() => {
            if (!this.mouseInsideControls) {
                this.hideMainControl = true;
            }
        }, 3000);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('media' in changes) {
            const mediaChange = changes['media'];
            this.loadMedia({ current: mediaChange.currentValue, prev: mediaChange.previousValue });
            this.resetAudioOptions();
            this.resetPlaybackRateOptions();
            this.resetMaterialMenu();
            this.resetAspectRatio();
        }
    }

    ngOnInit() {
        this._mediaCut$.pipe(takeUntil(this._destroyed$)).subscribe(mc => {
            this.mediaCutLoaded.emit(mc);
        });

        setTimeout(() => {
            if (!this.mouseInsideControls) {
                this.hideMainControl = !this.media?.isAudioProgram || false;
            }
        }, 3000);
    }

    ngOnDestroy() {
        this.playState.emit(this._player?.isPlaying());
        this._player?.destroy();
        this._destroyed$.next();
        this._destroyed$.complete();
        this.resizeObserver.unobserve(this.flowPlayerElement?.nativeElement);
        if (this.playerController) {
            this.focusMonitor.stopMonitoring(this.playerController);
        }
    }

    play() {
        this._player?.play();
    }

    pause() {
        this._player?.pause();
    }

    getCurrentVtc(): Duration | null {
        return this._currentVtc$.value;
    }

    get volumeLevel() {
        return this.isMuted ? 0 : this._player?.volume || 0;
    }

    get isMuted() {
        return this._player?.muted || false;
    }

    get aspectRatio() {
        return this._player?.aspectRatio || '16:9';
    }

    toggleMute(): void {
        this._player?.toggleMute();
    }

    onVolumeLevelChanged(newVolumeLevel: number | undefined): void {
        if (newVolumeLevel && this._player) {
            this._player.muted = newVolumeLevel == 0;
            this._player.volume = newVolumeLevel;
        }
    }

    jumpToStart(): void {
        this._player?.setCurrentLinearTime(fromMilliseconds(0));
    }

    jump(deltaInSeconds: number): void {
        const currentVtc = this.getCurrentVtc();
        const timeCodeMap = this._mediaCut$.value?.timeCodeMap;
        if (currentVtc && timeCodeMap) {
            const currentLtc = timeCodeMap.vtcToLtc(currentVtc);
            const newTime = currentLtc.add(fromSeconds(deltaInSeconds));
            this._player?.setCurrentLinearTime(newTime);
        }
    }

    jumpToVtc(vtc: Duration) {
        if (this._mediaCut$.value) {
            const ltc = this._mediaCut$.value.timeCodeMap.vtcToLtc(vtc);
            this._player?.setCurrentLinearTime(ltc);
        }
    }

    onRelativePositionChanged(newSliderValue: number | undefined) {
        if (newSliderValue && this.playerLoaded && this._mediaCut$.value) {
            const mc = this._mediaCut$.value;
            const lengthInMilliseconds = mc.length.asMilliseconds();
            const newLtc = mc.ltcIn.add(fromMilliseconds(lengthInMilliseconds * newSliderValue));
            this._player?.setCurrentLinearTime(newLtc);
        }
    }

    onRelativePositionHandleActivated(): void {
        this._player?.pauseDataLoading(true);
    }

    @HostListener('window:mouseup')
    onRelativePositionHandleDeactivated(): void {
        this._player?.pauseDataLoading(false);
    }

    isDataLoadingPaused(): boolean {
        return this._player?.isDataLoadingPaused || false;
    }

    pauseDataLoading(value: boolean): void {
        this._player?.pauseDataLoading(value);
    }

    onSpaceKeyUp(event: KeyboardEvent, playing: boolean) {
        if (event.code === 'Space') {
            playing ? this._player?.pause() : this._player?.play();
        }
    }

    openMenuAndSetFocus(event: Event, menu: Menu) {
        menu.toggle(event);
        if (menu.visible) {
            setTimeout(() => {
                if (menu) {
                    const menuItems = menu.el.nativeElement.getElementsByClassName('p-menuitem');
                    const activeIndex = Array.from(menuItems).findIndex((item: any) =>
                        item.classList.contains('active-menu-item')
                    );
                    if (activeIndex !== -1) {
                        setTimeout(() => {
                            menu.changeFocusedOptionIndex(activeIndex);
                        }, 10);
                    }
                }
            }, 100);
        }
    }

    closeOtherMenus(event: Event, menuOne: Menu, menuTwo: Menu) {
        if (menuOne.visible) menuOne.toggle(event);
        if (menuTwo.visible) menuTwo.toggle(event);
    }

    closeMenuOnMouseLeave(event: Event, menu: Menu) {
        if (menu.visible) menu.toggle(event);
    }

    private async initFlowPlayer(id: string) {
        const player = new HlsBackendFlowPlayer(this.videoService);
        await player.init(`#${id}`);

        this._player = player;
        this.playing$ = player.playing$;

        player.mediaLoaded$.pipe(takeUntil(this._destroyed$)).subscribe(m => {
            this._mediaCut$.next(m);
        });

        player.playedToEnd$.pipe(takeUntil(this._destroyed$)).subscribe(() => {
            this.requestNextMediaCutIfAvailable();
        });

        this.loadMedia({
            current: this.media,
            prev: null,
        });

        this.resetPlaybackRateOptions();
        this.resetAspectRatio();

        setTimeout(() => {
            this.playerLoaded = true;
        }, 0);
    }

    private resetAudioOptions() {
        const tracks = this.media?.audioTracks || [];
        const selectedId = this.media?.selectedAudioTrackId;
        const selectedTrack = tracks.find(t => t.id === selectedId);

        this.audioOptions = tracks.map(t => ({
            id: t.id.toString(),
            label: t.description,
            icon: selectedId === t.id ? 'pi pi-check' : '',
            styleClass: selectedId === t.id ? 'active-menu-item' : '',
            command: _ => (t.id !== selectedId ? this.requestAudioTrack(t.id) : null),
        }));

        this.isDefaultAudioOption = tracks.length === 0 || selectedTrack === tracks[0];
    }

    private resetPlaybackRateOptions() {
        const selectedPlaybackRate = this._player?.playbackRate;
        this.selectedPlaybackRateLabel = `${selectedPlaybackRate}x`;
        const options: MenuItem[] = [
            {
                label: '2x',
                icon: selectedPlaybackRate === 2 ? 'pi pi-check' : '',
                styleClass: selectedPlaybackRate === 2 ? 'active-menu-item' : '',
                'aria-label': 'Doppelte Geschwindigkeit',
                command: (_: any) => this.setPlaybackRate(2),
            },
            {
                label: '1.5x',
                icon: selectedPlaybackRate === 1.5 ? 'pi pi-check' : '',
                styleClass: selectedPlaybackRate === 1.5 ? 'active-menu-item' : '',
                'aria-label': '1.5 Geschwindigkeit',
                command: (_: any) => this.setPlaybackRate(1.5),
            },
            {
                label: '1.25x',
                icon: selectedPlaybackRate === 1.25 ? 'pi pi-check' : '',
                styleClass: selectedPlaybackRate === 1.25 ? 'active-menu-item' : '',
                'aria-label': '1.25 Geschwindigkeit',
                command: (_: any) => this.setPlaybackRate(1.25),
            },
            {
                label: '1x',
                icon: selectedPlaybackRate === 1 ? 'pi pi-check' : '',
                styleClass: selectedPlaybackRate === 1 ? 'active-menu-item' : '',
                'aria-label': 'Einfache Geschwindigkeit',
                command: (_: any) => this.setPlaybackRate(1),
            },
        ];
        this.playbackRateOptions = options.map(o => o);
    }

    private resetMaterialMenu() {
        const mediaCuts = this.media?.mediaCuts || [];
        const selectedId = this.media?.selectedMediaCutId;
        const selectedMediaCut = mediaCuts.find(mc => mc.id === selectedId);
        this.selectedMaterialLabel = selectedMediaCut?.description ?? '';
        this.materialOptions = mediaCuts.map(mc => ({
            id: mc.id,
            label: mc.description,
            icon: mc.id === selectedMediaCut?.id ? 'pi pi-check' : '',
            styleClass: mc.id === selectedMediaCut?.id ? 'active-menu-item' : '',
            command: _ => (mc.id !== selectedMediaCut?.id ? this.requestMediaCut(mc.id) : null),
        }));
    }

    private resetAspectRatio() {
        if (this.media && this._player) {
            this._player.aspectRatio =
                this.media.pictureAspectRatio === '4:3 Vollbild' || this.media.pictureAspectRatio === '4:3 Letterbox'
                    ? '4:3'
                    : '16:9';
        }
    }

    private requestAudioTrack(audioTrackId: string) {
        this.audioTrackRequested.emit(audioTrackId);
    }

    private requestNextMediaCutIfAvailable() {
        const nextMediaCut = this._mediaCut$.value?.nextCutId;
        if (nextMediaCut) {
            this.requestMediaCut(nextMediaCut);
        }
    }

    private requestMediaCut(mediaCut: string) {
        this.mediaCutRequested.emit(mediaCut);
    }

    private setPlaybackRate(playbackRate: number) {
        if (this._player) {
            this._player.playbackRate = playbackRate;
            this.resetPlaybackRateOptions();
        }
    }

    private loadMedia(args: { current: MediaInformation | null; prev: MediaInformation | null }) {
        const { current, prev } = args;

        const currentVtc = this.getCurrentVtc();

        let previousMediaCut;
        if (this._mediaCut$.value !== null) {
            previousMediaCut = this._mediaCut$.value;
            this._mediaCut$.next(null);
        }

        if (!current) return;

        const playNextMediaCut = previousMediaCut?.nextCutId === current.selectedMediaCutId;
        const sameContext = current.program === prev?.program && current.item === prev?.item;
        if (sameContext && currentVtc && !playNextMediaCut) {
            this._player!.mediaLoaded$.pipe(take(1)).subscribe(mc => {
                try {
                    const currentLtc = mc.timeCodeMap.vtcToLtc(currentVtc!);
                    this._player!.setCurrentLinearTime(currentLtc);
                } catch (_) {
                    // silently ignore exception - new media cut might not include currentVtc...
                }
            });
        }
        const keepPlaying =
            (sameContext && this._player?.isPlaying() === true) || (!sameContext && this.previousPlayerState);
        // autostart if keep playing (same context) or next media cut should be played ("verlinkter Ausschnitt")
        if (this._player) {
            this._player.playerState = this.previousPlayerState ?? false;
        }

        this._player?.load(current, keepPlaying || playNextMediaCut);
    }

    showVolumeBar() {
        clearTimeout(this.volumeBarTimeout);
        if (!this.displayVolumeBar) {
            this.displayVolumeBar = true;
        }
    }

    hideVolumeBar() {
        this.volumeBarTimeout = setTimeout(() => {
            this.displayVolumeBar = false;
        }, 5000);
    }

    onPlayBackRateMenuShow() {
        if (this.playBackRateMenu) {
            const menuItems = this.playBackRateMenu.el.nativeElement.querySelectorAll('li');
            menuItems.forEach((item: any, index: any) => {
                item.setAttribute('role', 'menuitem');
                item.setAttribute('aria-label', this.playbackRateOptions[index]['aria-label']);
            });
        }
    }

    onHideMenu(button: HTMLButtonElement) {
        setTimeout(() => {
            if (button) {
                button.focus();
            }
        }, 10);
    }
}

function calcRelativePosition(vtc: Duration, mc: MediaCutInfo): number {
    const elapsed = vtc.subtract(mc.vtcIn);
    return elapsed.asMilliseconds() / mc.length.asMilliseconds();
}
